import React, {useEffect} from 'react'
import { NewTag, BaseModal } from '@Modules'
import './ScheduleItemModal.scss'
import { InfoWithIcon, SVGAppStore, SVGGooglePlay, SVGPersons, SVGPosition } from 'src/modules/common'
import { CoachAccardeon } from 'src/modules/dashboard'
import { rootState } from '@State'
import { observer } from 'mobx-react-lite'
import {directionOfSession, parseTime, periodOfSession, roomOfSession} from 'src/pages/MainPage/utils/utils'
import moment from 'moment-timezone'
import { getStoreByIdFromCitites } from 'src/utils/state'
import { minutesParser } from '@Utils'
import {SVGAppGallery} from "../../../common/components/svgs/SVGAppGallery/SVGAppGallery";

interface ScheduleItemModalProps {
    isShow: boolean
    onClose: () => void
}

export const ScheduleItemModal: React.FC<ScheduleItemModalProps> = observer((props) => {
    const {
        isShow,
        onClose,
    } = props

    const mainPageState = rootState.mainPage
    const session = mainPageState.sessionInModal
    const sessionInfo = mainPageState.sessionInInfo
    const filter = rootState.filterMainPage
    const filteredItems = mainPageState.groupSessionsFiltered

    const coaches = mainPageState.coachProfiles.filter(c => session?.personnelId.includes(c.id))
    const coachesLength = session?.personnelId?.length


    const currentClubName = getStoreByIdFromCitites(mainPageState.citiesWithStores, filter.clubId)?.name || ''

    // CHANGE!!!!

    const durationMinutes = () => {
        if (!session) return 0;

        const hours = +parseTime(session.duration, "H");
        const minutes = +parseTime(session.duration, "M");

        return hours * 60 + minutes;
    }

    const durationText = () => {
        const minutes = durationMinutes()
        if (!minutes) return ''
        return `${minutes} ${minutesParser(minutes)}`
    }

    useEffect(() => {
        console.log(sessionInfo);
    }, [sessionInfo])

    return (
        <BaseModal
            isShow={isShow}
            onClose={onClose}
            timeout={300}
            className="wg-shedule-item-modal"
        >
            {
                session &&
                <>
                    <div className="wg-shedule-item-modal__content">
                        {session.novelty && <NewTag />}
                        <p className="wg-shedule-item-modal__title">
                            {session.name}
                        </p>
                        <p className="wg-shedule-item-modal__type">
                            {directionOfSession(session, filteredItems)?.name || ''}
                        </p>

                        <div className="wg-shedule-item-modal__time-block">
                            <span className="wg-shedule-item-modal__interval">
                                {periodOfSession(session, session.start, true)}
                            </span>
                            <span className="wg-shedule-item-modal__duration">
                                {durationText()}
                            </span>
                            <span className="wg-shedule-item-modal__date">
                                {moment(session.start).format('D MMMM, dddd')}
                            </span>
                        </div>

                        <div className="wg-shedule-item-modal__follow-block">
                            <p className="wg-shedule-item-modal__follow-title">
                                Для записи используй приложение
                            </p>
                            <div className="wg-shedule-item-modal__follow-icons">
                                <a
                                    href="https://play.google.com/store/apps/details?id=com.bitafit.client"
                                    target="_blank"
                                    className="wg-shedule-item-modal__follow-google"
                                >
                                    <SVGGooglePlay />

                                </a>
                                <a
                                    className="wg-shedule-item-modal__follow-apple"
                                    href="https://apps.apple.com/ru/app/bitafit-%D0%BA%D0%BB%D0%B8%D0%B5%D0%BD%D1%82/id1591904801"
                                    target="_blank"
                                >
                                    <SVGAppStore />
                                </a>
                                <a
                                    className="wg-shedule-item-modal__follow-gallery"
                                    href="https://appgallery.cloud.huawei.com/ag/n/app/C108553773?locale=en_US&source=appshare&subsource=C108553773&shareTo=org.telegram.messenger&shareFrom=appmarket&shareIds=2c65a27066b94390bed8d9ee5713adb0_org.telegram.messenger&callType=SHARE"
                                    target="_blank"
                                >
                                    <SVGAppGallery/>
                                </a>
                            </div>
                        </div>

                        <div className="wg-shedule-item-modal__info-blocks">
                            <InfoWithIcon
                                title={currentClubName}
                                descr={roomOfSession(session, filteredItems)?.name || ''}
                                Icon={<SVGPosition />}
                                className="wg-shedule-item-modal__info-item"
                            />
                            <InfoWithIcon
                                title="Стоимость"
                                descr={session.free ? 'Бесплатно' : 'Платно'}
                                Icon={<SVGPersons />}
                                className="wg-shedule-item-modal__info-item"
                            />
                            { session.maximumNumberParticipants !== null && <InfoWithIcon
                                title="Мест"
                                descr={`${session.registeredNumberParticipants}/${session.maximumNumberParticipants}`}
                                Icon={<SVGPersons />}
                                className="wg-shedule-item-modal__info-item"
                            />}
                        </div>
                        
                        <div className="wg-shedule-item-modal__coaches">
                            {
                                new Array(coachesLength).fill('').map((_, idx) => {
                                    const coach = coaches[idx]
                                    return (
                                        <CoachAccardeon
                                            key={idx}
                                            coach={coach}
                                            tabIndex={0}
                                            isLoading={mainPageState.isLoadingCoaches}
                                        />
                                    )
                                })
                            }
                        </div>
                        { sessionInfo && sessionInfo.description &&
                            <div className="wg-shedule-item-modal__descr-block">
                                <p className="wg-shedule-item-modal__descr-title">Описание</p>
                                <p className="wg-shedule-item-modal__descr-text">
                                    {sessionInfo.description}
                                </p>
                            </div>
                        }
                    </div>
                    <button
                        className="wg-shedule-item-modal__body-close"
                        onClick={onClose}
                    >
                        <svg width="20" height="20" fill="#111034" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3 3l7 7m0 0l7-7m-7 7l7 7m-7-7l-7 7" strokeWidth="2" strokeLinecap="round" />
                        </svg>
                    </button>
                </>
            }
        </BaseModal>
    )
})

const courseInfo = {
    descr: 'Тренировка направлена на проработку мышц брюшного пресса. спины и на развитие гибкости с использованием специальных упражнений на растягивание, увеличения подвижности суставов. Рекомендуется для всех уровней подготовленности.',
}
