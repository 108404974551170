import { COOKIE_TOKEN_ACCESS, MOCK_LOGIN, MOCK_PASSWORD } from '@Api'
import { action, makeAutoObservable } from 'mobx'
import AuthService from 'src/services/authService'

class AuthState {
    authorized: boolean | null = null
    isErrorAuth: boolean = false

    constructor() {
        makeAutoObservable(this, {
            setAuthorize: action.bound,
            login: action.bound,
            setErrorAuth: action.bound,
        })
    }

    setAuthorize = (val: boolean | null) => {
        this.authorized = val
    }

    login = async () => {
        try {
            const resp = await AuthService.login(MOCK_LOGIN, MOCK_PASSWORD)
            localStorage.setItem(COOKIE_TOKEN_ACCESS, resp.data.access_token)
            this.setAuthorize(true)
        } catch (e) {
            console.log(`Login error: ${e.response?.data?.message}`);
        }
    }

    setErrorAuth = (val: boolean) => {
        this.isErrorAuth = val
    }
}

export const authState = new AuthState()