import {
    authAxios,
    citiesUrl,
    clubsInCiytUrl,
    chainInCityUrl,
    groupSessionsUrl,
    allPersonnelInStoreUrl,
    axiosDefaultHeaders,
    personnelProfileUrl,
    directionsInStoreUrl, personnelPhoto,
    getSessionInfo
} from '@Api'
import { sleep } from '@Utils';
import { MOCK_DATA } from 'src/api/mockData';
import {
    CitiesList,
    DirectionInStore,
    GroupSessionsPeriod,
    PersonnelInStore,
    PersonnelPhoto,
    PersonnelProfile, SessionGroupSessionInfo,
    StoreChains,
    StoreCity
} from '@Types';
import moment from 'moment-timezone';

export class ApiService {
    static getCitiesWithStores = async () => {
        /*
            Get all cities

            https://app.bitafit.com/core_test/swagger-ui/index.html?configUrl=/core_test/v3/api-docs/swagger-config#/REST%20API%20%D0%B4%D0%BB%D1%8F%20%D0%BA%D0%BB%D0%B8%D0%B5%D0%BD%D1%82%D0%BE%D0%B2/get_api_localities
        */
        const resultCities: CitiesList[] = []
        const { data: cities } = await authAxios.get<CitiesList[]>(citiesUrl())

        await Promise.allSettled(
            cities.map(async (city) => {
                const clubs = await ApiService._getStoresOfCity(city)
                const newCity: CitiesList = {
                    ...city,
                    stores: clubs
                }
                resultCities.push(newCity)
            })
        )

        console.log('getCitiesWithStores', resultCities);
        return resultCities
    }

    static getGroupSessions = async (storeGuid: string, dateFrom: string, dateTo: string) => {
        /*
            Get all group programs by clubId and dates

            https://bfgroup.atlassian.net/wiki/spaces/NNTNR/pages/1764982802/API#%D0%9F%D0%BE%D0%BB%D1%83%D1%87%D0%B8%D1%82%D1%8C-%D0%B2%D1%81%D0%B5-%D0%B3%D1%80%D1%83%D0%BF%D0%BF%D0%BE%D0%B2%D1%8B%D0%B5-%D0%BF%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D1%8B-%D0%B2-%D0%BA%D0%BB%D1%83%D0%B1%D0%B5-%D0%B7%D0%B0-%D0%BF%D0%B5%D1%80%D0%B8%D0%BE%D0%B4-(2021-07-28)
        */


        const resp = await authAxios.get<GroupSessionsPeriod>(
            // Тестовый клуб, где есть данные - Екб -> БФ Каменск-Уральский

             groupSessionsUrl(
                 storeGuid,
                 dateFrom,
                 dateTo
             ),

            // MOCK
            /*groupSessionsUrl(
                storeGuid,
                moment('20210709').format(),
                moment('20210802')
                    .hours(23)
                    .minutes(59)
                    .seconds(59)
                    .format()
            ),*/
            axiosDefaultHeaders,
        )

        console.log('getGroupSessions FETCH', resp.data);
        return resp.data

        //return MOCK_DATA.groupSessionsResponse
    }

    static getDirectionsInStore = async (storeGuid: string) => {
        // Get direction in the store by storeGuid
        // https://bfgroup.atlassian.net/wiki/spaces/NNTNR/pages/1764982802/API#%D0%9C%D0%B5%D1%82%D0%BE%D0%B4-%D0%BF%D0%BE%D0%BB%D1%83%D1%87%D0%B5%D0%BD%D0%B8%D1%8F-%D0%B2%D1%81%D0%B5%D1%85-%D0%BD%D0%B0%D0%BF%D1%80%D0%B0%D0%B2%D0%BB%D0%B5%D0%BD%D0%B8%D0%B9-%D0%B3%D1%80%D1%83%D0%BF%D0%BF%D0%BE%D0%B2%D1%8B%D1%85-%D0%BF%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC-%D0%B2-%D0%BA%D0%BB%D1%83%D0%B1%D0%B5-%D0%BF%D0%BE-storeGuid-(2021-07-19)

        // IMPLEMENTATION

         const resp = await authAxios.get<DirectionInStore[]>(
             directionsInStoreUrl(storeGuid),
             axiosDefaultHeaders,
         )
         console.log('getDirectionsInStore FETCH', resp.data);
         return resp.data

        // MOCK
/*
        await sleep(1000)
        return Promise.resolve<DirectionInStore[]>(MOCK_DATA.directionsInStore)*/
    }

    static getAllPersonnelsInStore = async (storeId: number) => {
        /*
            Get coaches in the club by clubId

            https://app.bitafit.com/core_test/swagger-ui/index.html?configUrl=/core_test/v3/api-docs/swagger-config#/REST%20API%20%D0%B4%D0%BB%D1%8F%20%D0%BA%D0%BB%D0%B8%D0%B5%D0%BD%D1%82%D0%BE%D0%B2/get_api_stores__storeId__personnel
        */

        const { data: coaches } = await authAxios.get<PersonnelInStore[]>(
            allPersonnelInStoreUrl(storeId),
            axiosDefaultHeaders,
        )
        console.log('COACHES in the club', coaches)
        return coaches
    }

    static getPersonnelProfile = async (personnelId: number) => {
        /*
            Get information about the coach

            https://app.bitafit.com/core_test/swagger-ui/index.html?configUrl=/core_test/v3/api-docs/swagger-config#/REST%20API%20%D0%B4%D0%BB%D1%8F%20%D0%BA%D0%BB%D0%B8%D0%B5%D0%BD%D1%82%D0%BE%D0%B2/get_api_clients_personnel__personnelId__profile
        */

        const { data: profile } = await authAxios.get<PersonnelProfile>(
            personnelProfileUrl(personnelId),
            axiosDefaultHeaders,
        )

        console.log('PERSONNEL profile', profile)
        return profile
    }

    static getPersonnelPhotos = async (personnelGuid?: string) => {
        if(personnelGuid) {
            const res = await authAxios.get<PersonnelPhoto>(
                personnelPhoto(personnelGuid),
                axiosDefaultHeaders,
            )

            console.log('PERSONNEL photos', res.data)
            if(res.data) {
                return res.data
            }else{
                return {href: ""}
            }
        }else{
            return {
                href: ""
            };
        }
    }

    static getPhotoData = async (url: string) => {
        const res = await authAxios.get<any>(
            url,
            { responseType:"blob" }
        )

        return res.data
    }

    static getSessionInfo = async (guid: string) => {
        const res = await authAxios.get<SessionGroupSessionInfo>(
            getSessionInfo(guid),
            axiosDefaultHeaders
        )
        return res.data
    }

    static _getStoresOfCity = async (city: CitiesList) => {
        /*
            Get chains (networks) in the city and choose first chain

            https://app.bitafit.com/core_test/swagger-ui/index.html?configUrl=/core_test/v3/api-docs/swagger-config#/REST%20API%20%D0%B4%D0%BB%D1%8F%20%D0%BA%D0%BB%D0%B8%D0%B5%D0%BD%D1%82%D0%BE%D0%B2/get_api_store_chains
        */

        const { data: chains } = await authAxios.get<StoreChains[]>(chainInCityUrl(city.localityName))

        const firstChain = chains[0]

        /*
            Get clubs (stores) in the city by chainId

            https://app.bitafit.com/core_test/swagger-ui/index.html?configUrl=/core_test/v3/api-docs/swagger-config#/REST%20API%20%D0%B4%D0%BB%D1%8F%20%D0%BA%D0%BB%D0%B8%D0%B5%D0%BD%D1%82%D0%BE%D0%B2/get_api_store_chains__storeChainId__stores
        */

        const { data: stores } = await authAxios.get<StoreCity[]>(clubsInCiytUrl(firstChain.id, city.localityName))
        return stores
    }
}