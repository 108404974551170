import React from 'react'

interface SVGSuccessProps {
    fill?: string
    className?: string
}

export const SVGSuccess: React.FC<SVGSuccessProps> = (props) => {
    const {
        fill = '#00C0D1',
        className,
    } = props

    return (
        <svg
            width="20"
            height="20"
            fill={fill}
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        ><path d="M19 4.3824L6.57873 16.8037L1 11.2249L2.3824 9.84254L6.57873 14.0389L17.6176 3L19 4.3824Z" />
        </svg>
    )
}