export const BASE_API_URL = 'https://app.bitafit.com'
export const API_CORE_PREFIX = 'core'
//export const API_CORE_PREFIX = 'core_test'

const API_START = `${BASE_API_URL}/${API_CORE_PREFIX}/api`

//export const loginUrl = `${BASE_API_URL}/auth/realms/BitafitTest/protocol/openid-connect/token`
export const loginUrl = `${BASE_API_URL}/auth/realms/brightfit/protocol/openid-connect/token`

export const citiesUrl = () => `${API_START}/localities`

export const chainInCityUrl = (cityName: string) => `${API_START}/store_chains?locality_name=${cityName}`

export const clubsInCiytUrl = (storeChainId: number, cityName: string) => `${API_START}/store_chains/${storeChainId}/stores?locality_name=${cityName}`

export const groupSessionsUrl = (storeGuid: string, dateFrom: string, dateTo: string) => `${API_START}/stores/${storeGuid}/group_sessions?from=${encodeURIComponent(dateFrom)}&to=${encodeURIComponent(dateTo)}`

export const directionsInStoreUrl = (storeGuid: string) => `${API_START}/stores/${storeGuid}/directions`

export const allPersonnelInStoreUrl = (storeId: number) => `${API_START}/stores/${storeId}/personnel`

export const personnelProfileUrl = (personnelId: number) => `${API_START}/clients/personnel/${personnelId}/profile`

export const personnelPhoto = (personnelGuid: string) => `${API_START}/personnel/${personnelGuid}/photos/main`

export const getSessionInfo = (guid: string) => `${API_START}/group_sessions/${guid}`