import { action, makeAutoObservable, runInAction } from 'mobx';
import { ApiService } from '@Services';
import {
    CitiesList,
    CoachProfileWithId,
    DirectionInStore,
    GroupSessionsPeriod,
    PersonnelInStore,
    SessionGroupSessionInfo,
    SessionsGroupSession
} from '@Types';

class MainPageState {
    citiesWithStores: CitiesList[] = []
    isInitializedStores: boolean = false

    groupSessions: GroupSessionsPeriod | null = null
    groupSessionsFiltered: GroupSessionsPeriod | null = null
    isLoadingGroupSessions: boolean = false
    _lastRequestId: string = ''

    directionsInStore: DirectionInStore[] = []
    isLoadingDirectionsInStore: boolean = false

    personnelsInStore: PersonnelInStore[] = []
    isLoadingPersonnelIsnStore: boolean = false

    // Card modal
    sessionInModal: SessionsGroupSession | null = null
    sessionInInfo: SessionGroupSessionInfo | null = null
    //

    coachProfiles: CoachProfileWithId[] = []
    isLoadingCoaches: boolean = false

    showClubFilter: boolean = false

    constructor() {
        makeAutoObservable(
            this,
            {
                updateCitiesWithStore: action.bound,
                updateDirectionsInStore: action.bound,
                updatePersonnelsInStore: action.bound,
                updateGroupSession: action.bound,
                loadCoachProfile: action.bound,
                setSessionInModal: action.bound,
                setShowClubFilter: action.bound,
                cleanGroupSession: action.bound,
            }
        )
    }

    updateCitiesWithStore = async () => {
        try {
            const cities = await ApiService.getCitiesWithStores()
            runInAction(() => this.citiesWithStores = cities)
        } catch (err) {
            console.log(err)
        } finally {
            this.isInitializedStores = true
        }
    }

    updateDirectionsInStore = async (storeGuid: string) => {
        try {
            this.isLoadingDirectionsInStore = true
            const directions = await ApiService.getDirectionsInStore(storeGuid)
            runInAction(() => this.directionsInStore = directions)
        } catch (err) {
            console.log(err)
        } finally {
            runInAction(() => this.isLoadingDirectionsInStore = false)
        }
    }

    updatePersonnelsInStore = async (storeId: number) => {
        try {
            this.isLoadingPersonnelIsnStore = true
            const personnels = await ApiService.getAllPersonnelsInStore(storeId)
            runInAction(() => this.personnelsInStore = personnels)
        } catch (err) {
            console.log(err)
        } finally {
            runInAction(() => this.isLoadingPersonnelIsnStore = false)
        }
    }

    cleanGroupSession = () => {
        this.groupSessions = null
        this.groupSessionsFiltered = null
    }

    updateGroupSession = async (storeGuid: string, dateFrom: string, dateTo: string) => {
        try {
            // Prevent a few requests at the same time
            const reqId = Math.random() + ''
            this._lastRequestId = reqId
            //

            this.isLoadingGroupSessions = true
            const session = await ApiService.getGroupSessions(
                storeGuid,
                dateFrom,
                dateTo
            )
            if (this._lastRequestId === reqId) {
                runInAction(() => {
                    this.groupSessions = session
                    this.isLoadingGroupSessions = false
                })
            }
        } catch (err) {
            console.log(err)
            runInAction(() => this.isLoadingGroupSessions = false)
        }
    }

    setGroupSessionsFiltered = (val: GroupSessionsPeriod | null) => {
        this.groupSessionsFiltered = val
    }

    loadSessionInfo = async (guid: string) => {
        try{
            this.sessionInInfo = null;
            const session = await ApiService.getSessionInfo(guid);
            runInAction(() => {
                this.sessionInInfo = session
            })
        } catch (e) {
            console.log(e);
        }
    }

    loadCoachProfile = async (personnelId: number) => {
        try {
            if (this.coachProfiles.find(c => c.id === personnelId)) return null
            this.isLoadingCoaches = true
            const profile = await ApiService.getPersonnelProfile(personnelId)
            const photos = await ApiService.getPersonnelPhotos(this.personnelsInStore.find(prof => prof.id === personnelId)?.personnelGuid)
            let data = "";
            if(photos && photos.href){
                data = await ApiService.getPhotoData("https://static.bitafit.com/gallery"+photos.href);
            }
            runInAction(() => {
                this.coachProfiles = [...this.coachProfiles, { id: personnelId, photos: photos, photoData: data, ...profile }]
            })
        } catch (err) {
            console.log(err)
        } finally {
            runInAction(() => this.isLoadingCoaches = false)
        }
    }



    setSessionInModal = (val: SessionsGroupSession | null) => {
        this.sessionInModal = val
    }

    setShowClubFilter = (val: boolean) => {
        this.showClubFilter = val
    }
}

export const mainPageState = new MainPageState()