import React from 'react'
import './PersonsTag.scss'

interface PersonsTagProps {
    text: string
    className?: string
}

export const PersonsTag: React.FC<PersonsTagProps> = (props) => {
    const {
        className,
        text,
    } = props

    return (
        <div className={className ? `wg-persons-tag ${className}` : "wg-persons-tag"}>
            <svg
                width="12"
                height="12"
                xmlns="http://www.w3.org/2000/svg"
                className="wg-persons-tag__icon"
            >
                <path d="M5.4001 3.6a1.8 1.8 0 11-3.6 0 1.8 1.8 0 013.6 0zm4.8 0a1.801 1.801 0 01-.5272 1.2728A1.8 1.8 0 1110.2 3.6001zm-1.84 6.6c-.318 0-.56-.282-.56-.6a4.1819 4.1819 0 00-.317-1.6034c-.233-.5635-.1-1.284.504-1.368A2.9995 2.9995 0 019.9 7.002 3 3 0 0111.4 9.6a.6.6 0 01-.6.6h-2.44zM3.6 6.6a3 3 0 013 3 .6.6 0 01-.6.6h-4.8a.6.6 0 01-.6-.6 3 3 0 013-3z" />
            </svg>
            <span className="wg-persons-tag__text">
                {text}
            </span>
        </div>
    )
}