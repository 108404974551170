import React from 'react'

interface SVGPositionProps {
    fill?: string
    className?: string
}

export const SVGPosition: React.FC<SVGPositionProps> = (props) => {
    const {
        fill = '#00C0D1',
        className,
    } = props

    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill={fill}
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path fillRule="evenodd" clipRule="evenodd" d="M10.0002 18.3334C12.0835 18.3334 16.6668 14.1667 16.6668 8.33342C16.6668 4.65151 13.6821 1.66675 10.0002 1.66675C6.31826 1.66675 3.3335 4.65151 3.3335 8.33342C3.3335 13.3334 7.91683 18.3334 10.0002 18.3334ZM10.0002 11.6667C11.8411 11.6667 13.3335 10.1743 13.3335 8.33342C13.3335 6.49247 11.8411 5.00008 10.0002 5.00008C8.15921 5.00008 6.66683 6.49247 6.66683 8.33342C6.66683 10.1743 8.15921 11.6667 10.0002 11.6667Z" />
        </svg>
    )
}