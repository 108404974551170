import { rootState } from '@State'
import axios, { AxiosRequestConfig } from 'axios'
import AuthService from 'src/services/authService'
import { API_TOKEN_TYPE, COOKIE_TOKEN_ACCESS, MOCK_LOGIN, MOCK_PASSWORD } from './constants'
import { BASE_API_URL } from './urls'

export const authAxios = axios.create({
    withCredentials: false,
    baseURL: BASE_API_URL
})

authAxios.interceptors.request.use((config: AxiosRequestConfig) => {
    const access = localStorage.getItem(COOKIE_TOKEN_ACCESS)
    config.headers.Authorization = access ? `${API_TOKEN_TYPE} ${access}` : ''
    return config
})

authAxios.interceptors.response.use(
    (response) => {
        return response
    },
    async (err) => {
        const originalRequest = err.config
        if (err?.response?.status === 401 && originalRequest && !originalRequest._isRetry) {
            originalRequest._isRetry = true;
            try {
                const resp = await AuthService.login(MOCK_LOGIN, MOCK_PASSWORD)
                localStorage.setItem(COOKIE_TOKEN_ACCESS, resp.data.access_token)
                return authAxios.request(originalRequest)
            } catch (e) {
                console.log('НЕТ АВТОРИЗАЦИИ retry')
            }
        }else if(err?.response?.status === 404 || err?.response?.status === 403){
            return err?.response;
        }
        rootState.auth.setErrorAuth(true)
        throw err
    }
)

export const axiosDefaultHeaders = {
    headers: { 'Content-Type': 'application/vnd.bitafit.store-directions+json;v=1' }
}