import React, { useRef, useState } from 'react'
import { SVGDropArrow } from '@Modules'
import './InputSelect.scss'
import { SelectOption } from '@Types'
import { useRepositionList } from '../../hooks/useRepositionList'
import Scrollbars from 'react-custom-scrollbars-2'

interface InputSelectProps {
    options: SelectOption[]
    value: SelectOption['value'] | null
    onChange: (value: SelectOption['value'], option?: SelectOption) => void
    className?: string
    placeholer?: string
    withReset?: boolean
    disabled?: boolean
}

const clearOption = { value: '', text: 'Очистить' }

export const InputSelect: React.FC<InputSelectProps> = (props) => {
    const {
        className,
        options,
        value,
        withReset,
        placeholer = '',
        disabled,
        onChange,
    } = props

    const [focused, setFocused] = useState(false)
    const [opened, setOpened] = useState(false)
    const [search, setSearch] = useState('')

    const wrapperRef = useRef<HTMLDivElement>(null)

    const { listBodyRef, listStyles } = useRepositionList(opened)

    const currentOption = options.find(opt => opt.value === value)
    const searchedOptions = () => {
        if (!search) return options
        return options.filter(opt => opt.text.toLowerCase().includes(search.toLowerCase()))
    }

    const handleFocusInput = (e: React.FocusEvent<HTMLInputElement>) => {
        if (disabled) return null
        setFocused(true)
    }

    const handleBlurWrap = (e: React.FocusEvent<HTMLElement>) => {
        const related = e.relatedTarget as HTMLDivElement | null
        if (!related || !wrapperRef.current?.contains(related)) {
            setFocused(false)
            return setOpened(false)
        }
    }

    const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        const target = e.target as HTMLInputElement
        if (disabled) return null
        setSearch(target.value)
        onChange(clearOption.value, clearOption)
    }

    const handlekeyDownInput = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (disabled) return null
        if (e.key === 'ArrowUp' || e.key === 'ArrowDown') setOpened(true)
    }

    const handlekeyEnterOption = (key: string, option: SelectOption) => {
        if (disabled) return null
        if (key === 'Enter') handleClickItem(option)
    }

    const handleClickItem = (option: SelectOption) => {
        if (disabled) return null
        onChange(option.value, option)
        setOpened(false)
        setFocused(false)
        setSearch('')
    }

    const optionsIncludeValue = () => options.some(opt => opt.value === value)

    const wrapClasses = () => {
        const classes = ['wg-select']
        if (className) classes.push(className)
        if (focused) classes.push('focused')
        if (disabled) classes.push('disabled')
        if (focused || optionsIncludeValue() || search) classes.push('float-placeholder')
        if (searchedOptions().length && (opened || (search && focused))) classes.push('show-list')
        return classes.join(' ')
    }

    return (
        <div
            className={wrapClasses()}
            ref={wrapperRef}
            onBlur={handleBlurWrap}
            tabIndex={-1}
        >
            <div className="wg-select__wrapper">
                <div
                    className="wg-select__header"
                    onClick={() => setOpened(true)}
                >

                    <label className="wg-select__header-content">
                        <span
                            className="wg-select__header-floating-placeholder"
                        >
                            {placeholer}
                        </span>

                        <input
                            value={search || currentOption?.text || ''}
                            type="text"
                            className="wg-select__header-input"
                            disabled={disabled}
                            onFocus={handleFocusInput}
                            onInput={handleInput}
                            onKeyDown={handlekeyDownInput}
                        />

                        <SVGDropArrow className="wg-select__header-arrow" />
                    </label>

                </div>

                <div className="wg-select__list-wrapper">
                    <div
                        className="wg-select__list-body"
                        ref={listBodyRef}
                        style={listStyles}
                    >
                        <Scrollbars
                            style={{ width: '100%', overflowX: 'hidden' }}
                            autoHeight
                        >
                            <ul className="wg-select__list">
                                {
                                    Boolean(searchedOptions().length) && withReset &&
                                    <li
                                        tabIndex={opened ? 0 : -1}
                                        className="wg-select__list-item"
                                        onClick={() => handleClickItem(clearOption)}
                                        onKeyDown={e => handlekeyEnterOption(e.key, clearOption)}
                                    >
                                        <span className="wg-select__list-item-text clear">
                                            Очистить
                                        </span>
                                    </li>
                                }
                                {
                                    searchedOptions().map(opt => (
                                        <li
                                            tabIndex={opened ? 0 : -1}
                                            key={opt.value}
                                            className={value === opt.value ? "wg-select__list-item checked" : "wg-select__list-item"}
                                            onClick={() => handleClickItem(opt)}
                                            onKeyPress={e => handlekeyEnterOption(e.key, opt)}
                                        >
                                            <span className="wg-select__list-item-text">
                                                {opt.text}
                                            </span>
                                        </li>
                                    ))
                                }
                            </ul>
                        </Scrollbars>
                    </div>
                </div>
            </div>
        </div>
    )
}