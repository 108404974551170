import React from 'react'
import { SVGArrow } from 'src/modules/common'
import './BtnFilterSelect.scss'

interface BtnFilterSelectProps extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>{
    placeholder: string
    value: string
    className?: string
}

export const BtnFilterSelect: React.FC<BtnFilterSelectProps> = (props) => {
    const {
        className,
        placeholder,
        value,
        ...btnProps
    } = props

    return (
        <button
            className={className ? `${className} wg-filter-select-btn` : "wg-filter-select-btn"}
            {...btnProps}
        >
            <span className="wg-filter-select-btn__placeholder">
                {placeholder}
            </span>
            <span className="wg-filter-select-btn__value">
                {value}
            </span>
            <SVGArrow className="wg-filter-select-btn__arrow"/>
        </button>
    )
}