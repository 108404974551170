import { useEffect, useRef, useState } from 'react'

export const useRepositionList = (opened: boolean) => {

    const [listStyles, setListStyles] = useState<React.CSSProperties>({})
    const listBodyRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        const listBody = listBodyRef.current
        if (listBody && opened) {
            const windowHeight = window.innerHeight
            const listHeight = listBody.clientHeight
            const topOfList = listBody.getBoundingClientRect().top
            const bottomOfList = topOfList + listHeight

            const delta = bottomOfList - windowHeight

            if (delta > 0) {
                // if list is not visible on the window
                setListStyles(prev => ({
                    ...prev,
                    top: `-${delta}px`
                }))
            }
        }

        if (!opened) {
            // clear position style
            setListStyles(prev => ({
                ...prev,
                top: undefined
            }))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [listBodyRef.current, opened])

    return {
        listBodyRef,
        listStyles,
    }
}