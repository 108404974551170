import { AUTH_TOKEN_TYPE, BASE_TOKEN, loginUrl } from "@Api";
import { LoginResp } from "@Types";
import axios, { AxiosResponse } from "axios";
import qs from 'qs'

export default class AuthService {
    public static login = async (username: string, password: string): Promise<AxiosResponse<LoginResp>> => {
        return axios.post<LoginResp>(loginUrl,
            qs.stringify({
                grant_type: 'password',
                username,
                password,
            }),
            {
                headers: {
                    'Authorization': `${AUTH_TOKEN_TYPE} ${BASE_TOKEN}`,
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            },
        )
    }
}