export const API_TOKEN_TYPE = 'Bearer'
export const AUTH_TOKEN_TYPE = 'Basic'
export const COOKIE_TOKEN_ACCESS = 'btf-widg-acc'
// export const COOKIE_TOKEN_REFRESH = 'btf-widg-rf'

// Fixed User profile
/*export const BASE_TOKEN = process.env.NODE_ENV === 'production'
    ? 'cmFzcDoyMzY2MTQ4YS01ZGRlLTQ2MjctYmFlZC1kMTYxZTkyMzM2NjY='
    : 'bG9jYWxob3N0OmUwZWFhOTc0LTQ3NDQtNDlhNC05NzE5LWI2ZWMwNThkZWJiOQ=='*/
export const BASE_TOKEN = 'cmFzcDo5MTVlMWNjMC03OTJhLTRlZDgtOWM2My1jMzNlOTU2MjAyYzc=';
//export const BASE_TOKEN = 'bG9jYWxob3N0OmUwZWFhOTc0LTQ3NDQtNDlhNC05NzE5LWI2ZWMwNThkZWJiOQ==';
//export const MOCK_LOGIN = '71111111111'
//export const MOCK_PASSWORD = 'jYxFsR7euFMyhkbwd5'
export const MOCK_LOGIN = '71111111111'
export const MOCK_PASSWORD = 'jYxFsR7euFMyhkbwd5'
//

// Url search params (filter)
export const SEARCH_CITY = 'city_name'
export const SEARCH_CLUB = 'club_id'
export const SEARCH_DATE_FROM = 'date_from'
export const SEARCH_DATE_TO = 'date_to'
export const SEARCH_DIRECTIONS = 'directions'
export const SEARCH_COACHES = 'coaches'

export const SEARCH_TIME_PERIOD = 'time_period'
export const SEARCH_FREE_FIRST = 'free_first'
export const SEARCH_PAYMENT_LESSON = 'payment_lesson'
export const SEARCH_POPULAR = 'popular'
export const SEARCH_PRE_REGISTRATION = 'pre_registration'
//