import React from 'react'

interface SVGArrowProps {
    className?: string
}

export const SVGArrow: React.FC<SVGArrowProps> = (props) => {
    const {
        className
    } = props

    return (
        <svg width="20" height="20" fill="#051932" xmlns="http://www.w3.org/2000/svg" className={className}>
            <path fillRule="evenodd" clipRule="evenodd" d="M10 4.59l8.7 8.7-1.4 1.42L10 7.4l-7.3 7.3-1.4-1.42L10 4.6z" />
        </svg>
    )
}