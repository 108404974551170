import React from 'react'

interface SVGCalendarOffProps {
    className?: string
}

export const SVGCalendarOff: React.FC<SVGCalendarOffProps> = (props) => {
    const {
        className
    } = props

    return (
        <svg width="120" height="120" fill="#A3AAB4" xmlns="http://www.w3.org/2000/svg" className={className}>
            <path fillRule="evenodd" clipRule="evenodd" d="M31.8 13.8A6 6 0 0142 18v6h36v-6a6 6 0 1112 0v6h6a12 12 0 0112 12v60a12 12 0 01-12 12H24a12 12 0 01-12-12V36a12 12 0 0112-12h6v-6a6 6 0 011.8-4.2zm0 30A6 6 0 0136 42h48a6 6 0 110 12H36a6 6 0 01-4.2-10.2zm22.4 22a4.5 4.5 0 00-6.4 6.4l5.8 5.8-5.8 5.8a4.5 4.5 0 006.4 6.4l5.8-5.8 5.8 5.8a4.5 4.5 0 006.4-6.4L66.4 78l5.8-5.8a4.5 4.5 0 00-6.4-6.4L60 71.6l-5.8-5.8z" />
        </svg>
    )
}