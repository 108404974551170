import React from 'react'

interface SVGAppGalleryProps {
    className?: string
}

export const SVGAppGallery: React.FC<SVGAppGalleryProps> = (props) => {
    const {
        className
    } = props

    return (
        <svg width="140" height="40" viewBox="0 0 140 40" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
            <rect width="139.385" height="40" rx="5" fill="#051932"/>
            <g clip-path="url(#clip0_4802_16844)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M13.8563 6C8.10398 6 6 8.10361 6 13.8548V26.1452C6 31.8964 8.10398 34 13.8563 34H26.1399C31.8921 34 34 31.8964 34 26.1452V13.8548C34 8.10361 31.896 6 26.1437 6H13.8563Z" fill="url(#paint0_linear_4802_16844)"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M18.2137 22.2394H19.3378L18.7738 20.9279L18.2137 22.2394ZM17.9369 22.9004L17.6034 23.6635H16.8441L18.4588 20.0002H19.115L20.7232 23.6635H19.9436L19.6144 22.9004H17.9369ZM30.1552 23.6608H30.8891V20H30.1552V23.6608ZM27.2344 22.0882H28.5871V21.4207H27.2344V20.6704H29.1981V20.0027H26.5007V23.6633H29.2688V22.9956H27.2344V22.0882ZM24.3185 22.5207L23.4865 20H22.8793L22.0473 22.5207L21.2376 20.002H20.4459L21.7237 23.6655H22.3392L23.1726 21.2591L24.0059 23.6655H24.6268L25.9012 20.002H25.13L24.3185 22.5207ZM15.7238 22.0983C15.7238 22.6942 15.4279 23.0127 14.8905 23.0127C14.3501 23.0127 14.0526 22.685 14.0526 22.0729V20.0025H13.3089V22.0983C13.3089 23.1292 13.8818 23.7204 14.8803 23.7204C15.8888 23.7204 16.4669 23.118 16.4669 22.0677V20.0002H15.7238V22.0983ZM11.5344 20.0002H12.2777V23.6657H11.5344V22.1771H9.8551V23.6657H9.11133V20.0002H9.8551V21.4788H11.5344V20.0002Z" fill="white"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M20.0002 15.3334C17.4267 15.3334 15.3335 13.24 15.3335 10.6667H15.9927C15.9927 12.8763 17.7906 14.674 20.0002 14.674C22.2097 14.674 24.0077 12.8763 24.0077 10.6667H24.6668C24.6668 13.24 22.5734 15.3334 20.0002 15.3334Z" fill="white"/>
            </g>
            <path d="M47.1946 12.9067L42.7973 25.0132H41L46.0634 11.7299H47.222L47.1946 12.9067ZM50.8804 25.0132L46.4739 12.9067L46.4465 11.7299H47.6052L52.6868 25.0132H50.8804ZM50.6523 20.0958V21.5373H43.1896V20.0958H50.6523Z" fill="white"/>
            <path d="M55.73 17.0395V28.8084H54.0331V15.1419H55.584L55.73 17.0395ZM62.3808 19.9954V20.187C62.3808 20.9047 62.2956 21.5707 62.1253 22.185C61.955 22.7932 61.7056 23.3224 61.3772 23.7724C61.0549 24.2225 60.6565 24.5722 60.1821 24.8216C59.7077 25.071 59.1633 25.1956 58.549 25.1956C57.9226 25.1956 57.3691 25.0922 56.8886 24.8855C56.4081 24.6787 56.0006 24.3776 55.6661 23.9823C55.3316 23.5869 55.064 23.1125 54.8633 22.559C54.6686 22.0056 54.5348 21.3822 54.4618 20.6888V19.667C54.5348 18.9372 54.6717 18.2833 54.8724 17.7055C55.0731 17.1277 55.3377 16.6351 55.6661 16.2276C56.0006 15.814 56.4051 15.5008 56.8795 15.2879C57.3539 15.0689 57.9013 14.9595 58.5217 14.9595C59.142 14.9595 59.6925 15.0811 60.1729 15.3244C60.6534 15.5616 61.0579 15.9022 61.3863 16.3462C61.7148 16.7902 61.9611 17.3224 62.1253 17.9427C62.2956 18.557 62.3808 19.2413 62.3808 19.9954ZM60.6838 20.187V19.9954C60.6838 19.5028 60.6321 19.0405 60.5288 18.6087C60.4254 18.1708 60.2642 17.7876 60.0452 17.4592C59.8324 17.1247 59.5587 16.8632 59.2241 16.6746C58.8896 16.48 58.4912 16.3827 58.029 16.3827C57.6033 16.3827 57.2322 16.4557 56.916 16.6016C56.6058 16.7476 56.3412 16.9453 56.1223 17.1946C55.9033 17.4379 55.7239 17.7177 55.584 18.034C55.4502 18.3441 55.3498 18.6665 55.2829 19.001V21.3639C55.4046 21.7897 55.5749 22.1911 55.7938 22.5682C56.0128 22.9392 56.3047 23.2402 56.6697 23.4714C57.0346 23.6964 57.4938 23.8089 58.0473 23.8089C58.5034 23.8089 58.8957 23.7146 59.2241 23.5261C59.5587 23.3315 59.8324 23.0669 60.0452 22.7324C60.2642 22.3979 60.4254 22.0147 60.5288 21.5829C60.6321 21.145 60.6838 20.6797 60.6838 20.187Z" fill="white"/>
            <path d="M66.0534 17.0395V28.8084H64.3565V15.1419H65.9075L66.0534 17.0395ZM72.7042 19.9954V20.187C72.7042 20.9047 72.6191 21.5707 72.4488 22.185C72.2785 22.7932 72.0291 23.3224 71.7007 23.7724C71.3783 24.2225 70.98 24.5722 70.5056 24.8216C70.0311 25.071 69.4868 25.1956 68.8725 25.1956C68.246 25.1956 67.6926 25.0922 67.2121 24.8855C66.7316 24.6787 66.3241 24.3776 65.9896 23.9823C65.6551 23.5869 65.3875 23.1125 65.1867 22.559C64.9921 22.0056 64.8583 21.3822 64.7853 20.6888V19.667C64.8583 18.9372 64.9952 18.2833 65.1959 17.7055C65.3966 17.1277 65.6612 16.6351 65.9896 16.2276C66.3241 15.814 66.7286 15.5008 67.203 15.2879C67.6774 15.0689 68.2248 14.9595 68.8451 14.9595C69.4655 14.9595 70.0159 15.0811 70.4964 15.3244C70.9769 15.5616 71.3814 15.9022 71.7098 16.3462C72.0382 16.7902 72.2846 17.3224 72.4488 17.9427C72.6191 18.557 72.7042 19.2413 72.7042 19.9954ZM71.0073 20.187V19.9954C71.0073 19.5028 70.9556 19.0405 70.8522 18.6087C70.7488 18.1708 70.5877 17.7876 70.3687 17.4592C70.1558 17.1247 69.8821 16.8632 69.5476 16.6746C69.2131 16.48 68.8147 16.3827 68.3525 16.3827C67.9267 16.3827 67.5557 16.4557 67.2395 16.6016C66.9293 16.7476 66.6647 16.9453 66.4457 17.1946C66.2268 17.4379 66.0474 17.7177 65.9075 18.034C65.7737 18.3441 65.6733 18.6665 65.6064 19.001V21.3639C65.7281 21.7897 65.8984 22.1911 66.1173 22.5682C66.3363 22.9392 66.6282 23.2402 66.9931 23.4714C67.3581 23.6964 67.8173 23.8089 68.3707 23.8089C68.8269 23.8089 69.2192 23.7146 69.5476 23.5261C69.8821 23.3315 70.1558 23.0669 70.3687 22.7324C70.5877 22.3979 70.7488 22.0147 70.8522 21.5829C70.9556 21.145 71.0073 20.6797 71.0073 20.187Z" fill="white"/>
            <path d="M84.7611 18.3989V23.2615C84.5969 23.5048 84.3354 23.7785 83.9765 24.0826C83.6177 24.3806 83.122 24.6422 82.4894 24.8672C81.863 25.0862 81.0541 25.1956 80.0627 25.1956C79.2538 25.1956 78.5087 25.0558 77.8275 24.776C77.1524 24.4901 76.5655 24.0765 76.0667 23.5352C75.5741 22.9878 75.1909 22.3249 74.9172 21.5464C74.6496 20.7618 74.5158 19.8738 74.5158 18.8824V17.8515C74.5158 16.8601 74.6314 15.9752 74.8625 15.1967C75.0997 14.4181 75.4464 13.7582 75.9025 13.2169C76.3587 12.6695 76.9182 12.256 77.5812 11.9762C78.2441 11.6903 79.0044 11.5474 79.862 11.5474C80.8777 11.5474 81.7261 11.7238 82.4073 12.0765C83.0946 12.4232 83.6298 12.9037 84.013 13.518C84.4023 14.1323 84.6516 14.8317 84.7611 15.6163H83.0003C82.9213 15.1358 82.7631 14.6979 82.5259 14.3026C82.2948 13.9072 81.9633 13.591 81.5315 13.3538C81.0997 13.1105 80.5432 12.9888 79.862 12.9888C79.2477 12.9888 78.7155 13.1014 78.2654 13.3264C77.8153 13.5514 77.4443 13.8738 77.1524 14.2935C76.8605 14.7131 76.6415 15.221 76.4955 15.817C76.3556 16.4131 76.2857 17.0851 76.2857 17.8332V18.8824C76.2857 19.6488 76.3739 20.333 76.5503 20.9351C76.7327 21.5373 76.9912 22.0512 77.3257 22.4769C77.6603 22.8966 78.0586 23.2159 78.5209 23.4349C78.9892 23.6538 79.5062 23.7633 80.0718 23.7633C80.6983 23.7633 81.2061 23.7116 81.5954 23.6082C81.9846 23.4987 82.2887 23.371 82.5077 23.225C82.7266 23.073 82.8939 22.9301 83.0095 22.7962V19.8221H79.935V18.3989H84.7611Z" fill="white"/>
            <path d="M93.0775 23.3254V18.2438C93.0775 17.8545 92.9984 17.517 92.8403 17.2311C92.6882 16.9392 92.4571 16.7141 92.1469 16.556C91.8367 16.3979 91.4536 16.3188 90.9974 16.3188C90.5717 16.3188 90.1976 16.3918 89.8753 16.5378C89.559 16.6837 89.3096 16.8753 89.1272 17.1125C88.9508 17.3497 88.8626 17.6052 88.8626 17.8789H87.1748C87.1748 17.5261 87.266 17.1764 87.4485 16.8297C87.631 16.483 87.8925 16.1698 88.2331 15.89C88.5798 15.6042 88.9934 15.3791 89.4738 15.2149C89.9604 15.0446 90.5017 14.9595 91.0978 14.9595C91.8155 14.9595 92.448 15.0811 92.9954 15.3244C93.5489 15.5677 93.9807 15.9356 94.2909 16.4283C94.6071 16.9149 94.7653 17.5261 94.7653 18.262V22.8601C94.7653 23.1885 94.7927 23.5383 94.8474 23.9093C94.9082 24.2803 94.9964 24.5996 95.112 24.8672V25.0132H93.3512C93.266 24.8186 93.1991 24.5601 93.1505 24.2377C93.1018 23.9093 93.0775 23.6052 93.0775 23.3254ZM93.3694 19.0284L93.3877 20.2144H91.6817C91.2012 20.2144 90.7724 20.2539 90.3953 20.333C90.0182 20.406 89.7019 20.5185 89.4465 20.6706C89.191 20.8226 88.9964 21.0142 88.8626 21.2453C88.7288 21.4704 88.6619 21.7349 88.6619 22.039C88.6619 22.3492 88.7318 22.632 88.8717 22.8875C89.0116 23.1429 89.2214 23.3467 89.5012 23.4987C89.7871 23.6447 90.1368 23.7177 90.5504 23.7177C91.0674 23.7177 91.5235 23.6082 91.9189 23.3893C92.3142 23.1703 92.6274 22.9027 92.8585 22.5864C93.0957 22.2701 93.2235 21.963 93.2417 21.665L93.9624 22.4769C93.9199 22.7324 93.8043 23.0152 93.6158 23.3254C93.4272 23.6356 93.1748 23.9336 92.8585 24.2195C92.5484 24.4992 92.1773 24.7334 91.7455 24.9219C91.3198 25.1044 90.8393 25.1956 90.3041 25.1956C89.635 25.1956 89.0481 25.0649 88.5433 24.8033C88.0446 24.5418 87.6553 24.1921 87.3755 23.7542C87.1018 23.3102 86.965 22.8145 86.965 22.2671C86.965 21.738 87.0684 21.2727 87.2752 20.8713C87.482 20.4638 87.78 20.1262 88.1692 19.8586C88.5585 19.5849 89.0268 19.3781 89.5742 19.2382C90.1216 19.0983 90.7328 19.0284 91.408 19.0284H93.3694Z" fill="white"/>
            <path d="M99.0857 11V25.0132H97.3888V11H99.0857Z" fill="white"/>
            <path d="M103.461 11V25.0132H101.764V11H103.461Z" fill="white"/>
            <path d="M110.099 25.1956C109.411 25.1956 108.788 25.0801 108.228 24.849C107.675 24.6118 107.197 24.2803 106.796 23.8545C106.401 23.4288 106.097 22.924 105.884 22.3401C105.671 21.7562 105.564 21.1176 105.564 20.4242V20.0411C105.564 19.2382 105.683 18.5236 105.92 17.8971C106.157 17.2646 106.48 16.7293 106.887 16.2914C107.295 15.8535 107.757 15.522 108.274 15.297C108.791 15.072 109.326 14.9595 109.88 14.9595C110.585 14.9595 111.193 15.0811 111.704 15.3244C112.221 15.5677 112.644 15.9083 112.972 16.3462C113.301 16.778 113.544 17.2889 113.702 17.8789C113.86 18.4627 113.939 19.1014 113.939 19.7947V20.552H106.568V19.1744H112.252V19.0466C112.227 18.6087 112.136 18.183 111.978 17.7694C111.826 17.3558 111.583 17.0152 111.248 16.7476C110.914 16.48 110.457 16.3462 109.88 16.3462C109.496 16.3462 109.144 16.4283 108.821 16.5925C108.499 16.7506 108.222 16.9878 107.991 17.3041C107.76 17.6204 107.581 18.0066 107.453 18.4627C107.325 18.9189 107.261 19.445 107.261 20.0411V20.4242C107.261 20.8925 107.325 21.3335 107.453 21.7471C107.587 22.1546 107.778 22.5134 108.028 22.8236C108.283 23.1338 108.59 23.3771 108.949 23.5535C109.314 23.7299 109.728 23.818 110.19 23.818C110.786 23.818 111.291 23.6964 111.704 23.4531C112.118 23.2098 112.48 22.8844 112.79 22.4769L113.812 23.2889C113.599 23.6113 113.328 23.9184 113 24.2103C112.671 24.5023 112.267 24.7395 111.786 24.9219C111.312 25.1044 110.749 25.1956 110.099 25.1956Z" fill="white"/>
            <path d="M117.43 16.6929V25.0132H115.742V15.1419H117.384L117.43 16.6929ZM120.513 15.0872L120.504 16.6564C120.364 16.626 120.231 16.6077 120.103 16.6016C119.981 16.5895 119.841 16.5834 119.683 16.5834C119.294 16.5834 118.95 16.6442 118.652 16.7658C118.354 16.8875 118.102 17.0578 117.895 17.2767C117.688 17.4957 117.524 17.7572 117.402 18.0613C117.287 18.3594 117.211 18.6878 117.174 19.0466L116.7 19.3203C116.7 18.7243 116.758 18.1647 116.873 17.6417C116.995 17.1186 117.18 16.6564 117.43 16.2549C117.679 15.8474 117.995 15.5312 118.379 15.3061C118.768 15.075 119.23 14.9595 119.765 14.9595C119.887 14.9595 120.027 14.9747 120.185 15.0051C120.343 15.0294 120.453 15.0568 120.513 15.0872Z" fill="white"/>
            <path d="M124.843 23.9914L127.589 15.1419H129.395L125.436 26.5367C125.345 26.78 125.223 27.0416 125.071 27.3213C124.925 27.6072 124.736 27.8779 124.505 28.1333C124.274 28.3887 123.994 28.5955 123.666 28.7537C123.344 28.9179 122.957 29 122.507 29C122.374 29 122.203 28.9818 121.996 28.9453C121.79 28.9088 121.644 28.8784 121.559 28.854L121.549 27.4856C121.598 27.4916 121.674 27.4977 121.778 27.5038C121.887 27.516 121.963 27.522 122.006 27.522C122.389 27.522 122.714 27.4704 122.982 27.367C123.249 27.2696 123.474 27.1024 123.657 26.8652C123.845 26.6341 124.007 26.3147 124.14 25.9072L124.843 23.9914ZM122.827 15.1419L125.39 22.8054L125.828 24.5844L124.615 25.2048L120.984 15.1419H122.827Z" fill="white"/>
            <defs>
                <linearGradient id="paint0_linear_4802_16844" x1="20" y1="6" x2="20" y2="34" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FB6361"/>
                    <stop offset="1" stop-color="#ED3E45"/>
                </linearGradient>
                <clipPath id="clip0_4802_16844">
                    <rect width="28" height="28" fill="white" transform="translate(6 6)"/>
                </clipPath>
            </defs>
        </svg>

    )
}