import React from 'react'
import './NewTag.scss'

interface NewTagProps {
    className?: string
}

export const NewTag: React.FC<NewTagProps> = (props) => {
    const {
        className
    } = props

    return (
        <div className={className ? `wg-new-tag ${className}` : "wg-new-tag"}>
            <span className="wg-new-tag__text">
                new
            </span>
        </div>
    )
}