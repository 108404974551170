import React, {useRef, useState} from 'react'
import { SVGSuccess } from 'src/modules/common'
import './CoachCheckbox.scss'
import {CoachProfileWithId} from "@Types";

interface CoachCheckboxProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
    text: string
    className?: string,
    profile?: CoachProfileWithId
}

export const CoachCheckbox: React.FC<CoachCheckboxProps> = (props) => {
    const {
        text,
        className,
        profile,
        ...inputProps
    } = props

    const ref = useRef<any>()

    const inputId = inputProps.id || Math.random() + ''
    const [focused, setFocused] = useState(false)

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        setFocused(false)
        if (inputProps.onBlur) inputProps.onBlur(e)
    }

    const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
        setFocused(true)
        if (inputProps.onFocus) inputProps.onFocus(e)
    }

    const wrapClasses = () => {
        const classes = ["wg-coach-checkbox"]
        if (className) classes.push(className)
        if (focused) classes.push('focused')
        return classes.join(' ')
    }

    if(profile && profile.photoData) {
        let data = profile.photoData;
        let reader = new window.FileReader();
        reader.readAsDataURL(data);
        reader.onload = function () {
            let imageDataUrl = reader.result;
            ref.current.setAttribute("src", imageDataUrl);
        }
    }

    return (
        <div className={wrapClasses()}>
            <div
                className="wg-coach-checkbox__input-wrapper"
                onMouseLeave={() => setFocused(false)}
            >
                <input
                    type="checkbox"
                    className="wg-coach-checkbox__input"
                    {...inputProps}
                    id={inputId}
                    onBlur={handleBlur}
                    onFocus={handleFocus}
                />

                <div className="wg-coach-checkbox__content">
                    <img
                        src={"/static/img/person-icon.png"}
                        alt="input"
                        ref={ref}
                        className="wg-coach-checkbox__img"
                    />
                    <p className="wg-coach-checkbox__text">
                        {text}
                    </p>
                    <SVGSuccess className="wg-coach-checkbox__success-icon"/>
                </div>

                <label
                    htmlFor={inputId}
                    className="wg-coach-checkbox__label"
                >
                </label>
            </div>
        </div>
    )
}