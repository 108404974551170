import { SEARCH_CITY, SEARCH_CLUB, SEARCH_COACHES, SEARCH_DATE_FROM, SEARCH_DATE_TO, SEARCH_DIRECTIONS, SEARCH_FREE_FIRST, SEARCH_PAYMENT_LESSON, SEARCH_POPULAR, SEARCH_PRE_REGISTRATION, SEARCH_TIME_PERIOD } from "@Api"
import { rootState } from "@State"
import { useEffect } from "react"
import { TimePeriodFilter } from "src/utils/state"
import { useQueryString } from "use-route-as-state"

export const useSearchUpdater = () => {

    const filter = rootState.filterMainPage
    const [query, setQuery] = useQueryString()

    const timePeriodToUrl = (period: TimePeriodFilter) => {
        return Object
            .entries(period)
            .map(([periodKey, dates]) => {
                return `${periodKey}__${dates[0]},${dates[1]}`
            })
            .join('____')
    }

    useEffect(() => {
        setQuery({
            ...query,
            [SEARCH_CITY]: filter.cityName,
            [SEARCH_CLUB]: filter.clubId ? filter.clubId.toString() : '',
            [SEARCH_DATE_FROM]: filter.dateFrom,
            [SEARCH_DATE_TO]: filter.dateTo,
            [SEARCH_DIRECTIONS]: filter.directionIds.join(','),
            [SEARCH_COACHES]: filter.coachesIds.join(','),

            [SEARCH_TIME_PERIOD]: timePeriodToUrl(filter.timePeriods),
            [SEARCH_FREE_FIRST]: filter.isFreeFirstLesson ? '1' : '0',
            [SEARCH_PAYMENT_LESSON]: filter.isPaymentLesson ? '1' : '0',
            [SEARCH_POPULAR]: filter.isPopular ? '1' : '0',
            [SEARCH_PRE_REGISTRATION]: filter.isPreRegistration ? '1' : '0',

        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        filter.cityName,
        filter.clubId,

        filter.dateFrom,
        filter.dateTo,

        filter.directionIds,

        filter.coachesIds,

        filter.timePeriods,
        filter.isFreeFirstLesson,
        filter.isPaymentLesson,
        filter.isPopular,
        filter.isPreRegistration,
    ])
}