import React, { useState } from 'react'
import { SwitchCheckbox } from 'src/modules/common'
import './SwitchIconText.scss'

interface SwitchIconTextProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
    text: string
    Icon: React.FC<{ className?: string }> | React.ComponentClass<{ className?: string }>
    className?: string
}

export const SwitchIconText: React.FC<SwitchIconTextProps> = (props) => {
    const {
        text,
        Icon,
        className,
        ...inputProps
    } = props

    const inputId = inputProps.id || Math.random() + '-switch'

    const [focused, setFocused] = useState(false)

    const wrapClasses = () => {
        const classes = ["wg-switch-icon-text"]
        if (className) classes.push(className)
        if (focused) classes.push('focused')
        if (inputProps.disabled) classes.push('disabled')
        if (inputProps.checked) classes.push('checked')
        return classes.join(' ')
    }

    return (
        <div className={wrapClasses()}>
            <label
                htmlFor={inputId}
                className="wg-switch-icon-text__label"
                onMouseLeave={() => setFocused(false)}
            >
                <Icon className="wg-switch-icon-text__icon" />
                <span className="wg-switch-icon-text__text">
                    {text}
                </span>
            </label>
            <SwitchCheckbox
                {...inputProps}
                id={inputId}
            />
        </div>
    )
}
