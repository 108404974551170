import { CitiesList, StoreCity } from '@Types';

export const getStoreByIdFromCitites = (cities: CitiesList[], clubId: number | string): StoreCity | null => {
    let store = null
    cities.forEach(city => {
        if (city.stores) {
            city.stores.forEach(club => {
                if (club.id + '' === clubId + '') store = club
            })
        }
    })
    return store
}

export type TimePeriodFilter = {[periodKey: string]: [string, string]}