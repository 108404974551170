import React from 'react'
import { SquareCheckbox } from 'src/modules/common'
import './TimeCheckbox.scss'

interface TimeCheckboxProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
    title: string
    descr: string
    className?: string
}

export const TimeCheckbox: React.FC<TimeCheckboxProps> = (props) => {
    const {
        title,
        descr,
        className,
        ...inputProps
    } = props

    const inputId = inputProps.id || Math.random() + '-square-chbox'

    const wrapClasses = () => {
        const classes = ["wg-time-checkbox"]
        if (className) classes.push(className)
        return classes.join(' ')
    }

    return (
        <div className={wrapClasses()}>
            <div
                className="wg-time-checkbox__wrapper"
            >
                <SquareCheckbox
                    className="wg-time-checkbox__fake-input"
                    {...inputProps}
                    id={inputId}
                />
                <label
                    htmlFor={inputId}
                    className="wg-time-checkbox__label"
                >
                    <span className="wg-time-checkbox__title">
                        {title}
                    </span>
                    <span className="wg-time-checkbox__descr">
                        {descr}
                    </span>
                </label>
            </div>
        </div>
    )
}