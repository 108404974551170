import React from 'react'
import './InfoWithIcon.scss'

interface InfoWithIconProps {
    title: string
    descr: string
    Icon: JSX.Element
    className?: string
}

export const InfoWithIcon: React.FC<InfoWithIconProps> = (props) => {
    const {
        title,
        descr,
        Icon,
        className,
    } = props

    return (
        <div className={className ? `${className} wg-info-with-icon` : "wg-info-with-icon"}>
            <div className="wg-info-with-icon__icon-block">
                {Icon}
            </div>
            <div className="wg-info-with-icon__text-block">
                <p className="wg-info-with-icon__title">
                    {title}
                </p>
                <p className="wg-info-with-icon__descr">
                    {descr}
                </p>
            </div>
        </div>
    )
}