import React from 'react'

interface SVGGiftProps {
    className?: string
}

export const SVGGift: React.FC<SVGGiftProps> = (props) => {
    const {
        className
    } = props

    return (
        <svg width="20" height="20" fill="#A3AAB4" xmlns="http://www.w3.org/2000/svg" className={className}>
            <path d="M17.2 16v-4.44c.25-.19.45-.42.59-.7.14-.26.21-.56.21-.86V8c0-.48-.18-.94-.51-1.3-.33-.36-.79-.6-1.29-.67a2.82 2.82 0 00-.38-2.75c-.3-.4-.69-.72-1.14-.94A3.37 3.37 0 0013.2 2 4.5 4.5 0 0010 3.36 4.5 4.5 0 006.8 2c-.51 0-1.02.12-1.48.34-.45.22-.84.55-1.14.94a2.9 2.9 0 00-.38 2.75c-.5.07-.96.31-1.29.67-.33.36-.5.82-.51 1.3v2c0 .3.07.6.21.87.14.27.34.5.59.69V16c0 .53.22 1.04.62 1.41.4.38.95.59 1.51.59h10.14c.56 0 1.1-.21 1.5-.59.4-.37.63-.88.63-1.41zM6.8 8h2.13v2h-4.8V8H6.8zm4.27 0h4.8v2h-4.8V8zm2.13-4c.28 0 .55.1.75.3.2.18.32.43.32.7 0 .27-.12.52-.32.7-.2.2-.47.3-.75.3h-2.13c0-.53.22-1.04.62-1.41.4-.38.94-.59 1.51-.59zM6.8 4c.57 0 1.1.21 1.5.59.4.37.63.88.63 1.41H6.8c-.28 0-.55-.1-.75-.3a.97.97 0 01-.32-.7c0-.27.12-.52.32-.7.2-.2.47-.3.75-.3zM4.93 16v-4h4v4h-4zm6.14-4h4v4h-4v-4z" />
        </svg>
    )
}