import { BtnFilterSelect } from '@Modules'
import { rootState } from '@State'
import { isCheckedEveryDirection } from '@Utils'
import { observer } from 'mobx-react-lite'
import React from 'react'
import './SelectLineMainPage.scss'

interface SelectLineMainPageProps {
    onClickDirection: () => void
    onClickCoach: () => void
    onClickParticular: () => void,
    onClickDirectionMain: () => void,
    isMob: boolean
}

export const SelectLineMainPage: React.FC<SelectLineMainPageProps> = observer((props) => {
    const {
        onClickDirection,
        onClickCoach,
        onClickParticular,
        onClickDirectionMain,
        isMob
    } = props

    const mainPageState = rootState.mainPage
    const filter = rootState.filterMainPage

    const isSelectedClub = Boolean(rootState.filterMainPage.clubId)

    const directionText = () => {
        if (!isSelectedClub) return 'Не выбрано'

        const selected = filter.directionIds.length
        if (isCheckedEveryDirection(mainPageState.directionsInStore, selected)) {
            return 'Все'
        }
        if (selected) return `Выбрано ${selected}`
        return 'Не выбрано'
    }

    const coachesText = () => {
        if (!isSelectedClub) return 'Не выбрано'

        const selected = filter.coachesIds.length
        if (mainPageState.personnelsInStore.length === selected) {
            return 'Все'
        }
        if (selected) return `Выбрано ${selected}`
        return 'Не выбрано'
    }

    const particularText = () => {
        if (!isSelectedClub) return 'Не выбрано'

        const maxFilters = 7
        let selected = 0

        if (filter.timePeriods.hasOwnProperty('morning')) ++selected
        if (filter.timePeriods.hasOwnProperty('evening')) ++selected
        if (filter.timePeriods.hasOwnProperty('afternoon')) ++selected

        if (filter.isFreeFirstLesson) ++selected
        if (filter.isPaymentLesson) ++selected
        if (filter.isPopular) ++selected
        if (filter.isPreRegistration) ++selected

        if (maxFilters === selected) {
            return 'Все'
        }
        if (selected) return `Выбрано ${selected}`
        return 'Не выбрано'
    }

    const wrapClasses = () => {
        const classes = ['wg-select-linemain-page']
        if (!isSelectedClub) classes.push('disable')
        return classes.join(' ')
    }

    return (
        <div className={wrapClasses()}>
            {isMob && <BtnFilterSelect
                placeholder="Расписание"
                value={directionText()}
                className="wg-select-linemain-page__item"
                onClick={isSelectedClub ? onClickDirectionMain : undefined}
            />}
            <BtnFilterSelect
                placeholder="Направления"
                value={directionText()}
                className="wg-select-linemain-page__item"
                onClick={isSelectedClub ? onClickDirection : undefined}
            />
            {/* <BtnFilterSelect
                            placeholder="Занятия"
                            value="Все"
                            className="wg-select-linemain-page__item"
                            onClick={() => setShowDirectionFilter(true)}
                        /> */}
            <BtnFilterSelect
                placeholder="Инструкторы"
                value={coachesText()}
                className="wg-select-linemain-page__item"
                onClick={isSelectedClub ? onClickCoach : undefined}
            />
            <BtnFilterSelect
                placeholder="Особености"
                value={particularText()}
                className="wg-select-linemain-page__item"
                onClick={isSelectedClub ? onClickParticular : undefined}
            />
        </div>
    )
})