import React, { useRef } from 'react'
import './RadioBtn.scss'

interface RadioBtnProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
    text: string
    type?: string
    className?: string
}

export const RadioBtn: React.FC<RadioBtnProps> = (props) => {
    const {
        text,
        className,
        type = 'radio',
        ...inputProps
    } = props

    const inputRef = useRef<HTMLInputElement>(null)

    const clearFocus = () => {
        inputRef.current?.blur()
    }

    return (
        <div className={className ? `${className} wg-radio-btn` : "wg-radio-btn"}>
            <label
                className={inputProps.disabled ? "wg-radio-btn__label disabled" : "wg-radio-btn__label"}
                onMouseLeave={clearFocus}
            >
                <input
                    type={type}
                    className="wg-radio-btn__input"
                    ref={inputRef}
                    {...inputProps}

                />
                <span className="wg-radio-btn__bg"></span>
                <span className="wg-radio-btn__text">
                    {text}
                </span>
            </label>
        </div>
    )
}