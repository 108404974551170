import React from 'react';
import 'react-app-polyfill/ie11';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import '@Assets'
import App from './components/App/App';
import reportWebVitals from './reportWebVitals';
import moment from 'moment'
import 'moment/locale/ru'
moment.locale('ru')

ReactDOM.render(
    <Router>
        <App />
    </Router>,
  document.getElementById('root')
);

reportWebVitals();
