import { SEARCH_CITY, SEARCH_CLUB, SEARCH_COACHES, SEARCH_DATE_FROM, SEARCH_DATE_TO, SEARCH_DIRECTIONS, SEARCH_FREE_FIRST, SEARCH_PAYMENT_LESSON, SEARCH_POPULAR, SEARCH_PRE_REGISTRATION, SEARCH_TIME_PERIOD } from "@Api"
import { rootState } from "@State"
import { useEffect } from "react"
import { TimePeriodFilter } from "src/utils/state"
import { useQueryString } from "use-route-as-state"

export const useMountFilter = () => {
    /*
        The hook updates state of the filter from search url after mount component
    */

    const filter = rootState.filterMainPage
    const mainPageState = rootState.mainPage
    const [query] = useQueryString()

    const timePeriodToObj = (periodSearch: string) => {
        const pairs = periodSearch.split('____')
        const entries = pairs.map(pair => {
            const [periodKey, datesString] = pair.split('__')
            const dates = datesString.split(',')
            return [periodKey, dates]
        })
        return Object.fromEntries(entries) as TimePeriodFilter
    }


    useEffect(() => {
        const clubId = +query[SEARCH_CLUB]

        const directionIds = query[SEARCH_DIRECTIONS]
            ? query[SEARCH_DIRECTIONS]
                .split(',')
                .map(id => +id)
                .filter(id => !Number.isNaN(id))
            : []

        const coachIds = query[SEARCH_COACHES]
            ? query[SEARCH_COACHES]
                .split(',')
                .map(id => +id)
                .filter(id => !Number.isNaN(id))
            : []

        if (query[SEARCH_CITY]) filter.setCityName(query[SEARCH_CITY])
        if (clubId) filter.setClubId(clubId)
        if (query[SEARCH_DATE_FROM]) filter.setDateFrom(query[SEARCH_DATE_FROM])
        if (query[SEARCH_DATE_TO]) filter.setDateTo(query[SEARCH_DATE_TO])
        if (directionIds.length) filter.setDirectionIds(directionIds)
        if (coachIds.length) filter.setCoachesIds(coachIds)


        
        if (query[SEARCH_TIME_PERIOD]) filter.setTimePeriod(timePeriodToObj(query[SEARCH_TIME_PERIOD]))
        if (query[SEARCH_FREE_FIRST]) filter.setIsFreeFirstLesson(Boolean(+query[SEARCH_FREE_FIRST]))
        if (query[SEARCH_PAYMENT_LESSON]) filter.setIsPaymentLesson(Boolean(+query[SEARCH_PAYMENT_LESSON]))
        if (query[SEARCH_POPULAR]) filter.setIsPopular(Boolean(+query[SEARCH_POPULAR]))
        if (query[SEARCH_PRE_REGISTRATION]) filter.setIsPreRegistration(Boolean(+query[SEARCH_PRE_REGISTRATION]))

        // if the clubId is provided by URL Search, force hide Club filter.
        if (!clubId) {
            mainPageState.setShowClubFilter(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
}