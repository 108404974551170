import { GroupSessionsPeriod, SessionsGroupSession } from "@Types"
import moment from "moment-timezone"

export const directionOfSession = (session: SessionsGroupSession, groupSessionsFiltered: GroupSessionsPeriod | null) => {
    return groupSessionsFiltered?.groupSessionDirections.find(direction => direction.id === session.groupSessionDirectionId)
}

export const roomOfSession = (session: SessionsGroupSession, groupSessionsFiltered: GroupSessionsPeriod | null) => {
    return groupSessionsFiltered?.rooms.find(room => room.id === session.storeRoomId)
}

export const parseTime = (time : string, unit : string) => {
    let exp = new RegExp("\\d+"+unit);
    let itog = time.match(exp);
    if(itog == null) return 0;
    let tt = itog[0].match(/\d+/g);
    if(tt == null) return 0;
    return tt[0];
}

export const periodOfSession = (session: SessionsGroupSession, startTime: string, parseStartTime = false) => {
    // only numbers
    //const period = session.duration.match(/\d+/g)
    let start = startTime

    const endTime = moment(session.start)
        .add(parseTime(session.duration, "H"), 'hours')
        .add(parseTime(session.duration, "M"), 'minutes')
        .format('HH:mm')

    console.log(endTime, session.duration, parseTime(session.duration, "H"), parseTime(session.duration, "M"));

    if (parseStartTime) start = moment(session.start).format('HH:mm')
    return `${start} - ${endTime}`
}