import React, { useState } from 'react'
import { SVGSearch } from 'src/modules/common'
import './InputFind.scss'

interface InputFindProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
    className?: string
}

export const InputFind: React.FC<InputFindProps> = (props) => {
    const {
        className,
        ...inputProps
    } = props

    const [focused, setFocused] = useState(false)

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        setFocused(false)
        if (inputProps.onBlur) inputProps.onBlur(e)
    }

    const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
        setFocused(true)
        if (inputProps.onFocus) inputProps.onFocus(e)
    }

    return (
        <div className="wg-input-find">
            <div className="wg-input-find__line">
                <label
                    className={focused ? "wg-input-find__label focused" : "wg-input-find__label"}
                >
                    <input
                        type="text"
                        className="wg-input-find__input"
                        {...inputProps}
                        onBlur={handleBlur}
                        onFocus={handleFocus}
                    />
                </label>
                <button className="wg-input-find__find-btn">
                    <SVGSearch
                        className="wg-input-find__find-icon"
                    />
                </button>
            </div>

        </div>
    )
}