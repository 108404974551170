import React from 'react'
import { NewTag, IconTag, PersonsTag, } from '@Modules'
import './ScheduleCard.scss'
import {CancelTag} from "../../../common/components/tags/CancelTag/CancelTag";
import {RubTag} from "../../../common/components/tags/RubTag/RubTag";

interface ScheduleCardProps {
    title: string
    direction: string
    room: string
    coachName: string
    date: string
    busyPlaces: number
    maxPlaces: number
    isNovelty: boolean
    isFree: boolean,
    isCancel: boolean,
    className?: string
    onClick: () => void
}

export const ScheduleCard: React.FC<ScheduleCardProps> = (props) => {
    const {
        className,
        title,
        direction,
        room,
        coachName,
        date,
        busyPlaces,
        maxPlaces,
        isNovelty,
        isFree,
        isCancel,
        onClick,
    } = props

    const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === 'Enter') onClick()
    }

    return (
        <div
            className={className ? `${className} wg-shedule-card` : "wg-shedule-card"}
            tabIndex={1}
            onClick={onClick}
            onKeyDown={handleKeyDown}
        >
            <p className="wg-shedule-card__title">
                {title}
            </p>
            <p className="wg-shedule-card__descr">
                {direction}
            </p>

            <div className="wg-shedule-card__tags-block">
                {
                    isCancel && <CancelTag className="wg-shedule-card__tag"/>
                }
                {
                    isNovelty && <NewTag className="wg-shedule-card__tag" />
                }
                {(maxPlaces !== null) && <PersonsTag
                    text={`${busyPlaces}/${maxPlaces}`}
                    className="wg-shedule-card__tag"
                />}
                {
                    isFree ?
                    <IconTag
                        type="gift"
                        className="wg-shedule-card__tag"
                    /> : <RubTag/>
                }

                <IconTag type="success" className="wg-shedule-card__tag" />
            </div>

            <p className="wg-shedule-card__info">
                {room}
            </p>

            <p className="wg-shedule-card__info">
                {coachName}
            </p>

            <p className="wg-shedule-card__time">
                {date}
            </p>
        </div>
    )
}