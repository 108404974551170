import { rootState } from "@State"
import { SessionsGroupSession } from "@Types"
import moment from "moment-timezone"
import { useEffect } from "react"
import { TimePeriodFilter } from "src/utils/state"

export const useClientFilter = () => {

    const filter = rootState.filterMainPage
    const mainPageState = rootState.mainPage

    const isMatchPeriod = (periodDate: TimePeriodFilter, session: SessionsGroupSession): boolean => {
        if (!Object.keys(periodDate).length) return true
        const periods = Object.values(periodDate)
        return periods.some(period => {
            const sessionDate = parseInt(moment(session.start).format('Hmmss'))
            const start = parseInt(moment(period[0]).format('Hmmss'))
            const end = parseInt(moment(period[1]).format('Hmmss'))
            //const passedMinDate = start.isBefore(sessionDate) || start.isSame(sessionDate)
            //const passedMaxDate = end.isAfter(sessionDate) || end.isSame(sessionDate)
            return sessionDate >= start && sessionDate <= end
        })
    }

    useEffect(() => {
        const allSessionsPeriod = mainPageState.groupSessions
            ? { ...mainPageState.groupSessions }
            : mainPageState.groupSessions
        if (!allSessionsPeriod) return mainPageState.setGroupSessionsFiltered(allSessionsPeriod)

        const newSessions = [...allSessionsPeriod.groupSessions]
            .filter(session => {
                let show = true
                if (show){
                    show = filter.directionIds.includes(session.groupSessionDirectionId)
                    console.log('first', show, filter.directionIds, session);
                }
                if (show && filter.coachesIds.length) {
                    show = filter.coachesIds.some(id => session.personnelId.includes(id))
                    console.log('second', show, filter.coachesIds, session);
                }
                if (show && filter.isPreRegistration){
                    show = session.preEntryEnabled === filter.isPreRegistration
                    console.log('third', show, filter.isPreRegistration, session);
                }
                if (show && filter.isPaymentLesson){
                    show = session.free !== filter.isPaymentLesson
                    console.log('four', show, filter.isPaymentLesson, session);
                }
                if (show && filter.timePeriods){
                    show = isMatchPeriod(filter.timePeriods, session)
                    console.log('five', show, filter.timePeriods, session);
                }
                // if (show && filter.isFreeFirstLesson) show = session.freeFirst === filter.isFreeFirstLesson
                // if (show && filter.isPopular) show = session.isPopular === filter.isPopular

                return show
            })
        console.log(filter);
        allSessionsPeriod.groupSessions = newSessions
        mainPageState.setGroupSessionsFiltered(allSessionsPeriod)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        mainPageState.groupSessions,
        filter.directionIds,
        filter.coachesIds,
        filter.timePeriods,
        filter.isPopular,
        filter.isPreRegistration,
        filter.isFreeFirstLesson,
        filter.isPaymentLesson,
    ])
}
