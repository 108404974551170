import React from "react";
import {rootState} from "@State";
import './ChangeView.scss';
import {observer} from "mobx-react-lite";

export const ChangeView = observer(() => {
    const filter = rootState.filterMainPage

    const handleClick = (isView: boolean) => {
        filter.setViewDay(isView);
    }

    return(<div className="wg-change-view">
        <div className={"wg-change-view__item" + (filter.viewDay ? " wg-change-view__item-active" : "")}
             onClick={() => handleClick(true)}>
            1 день
        </div>
        <div className={"wg-change-view__item" + (!filter.viewDay ? " wg-change-view__item-active" : '')}
             onClick={() => handleClick(false)}>
            7 дней
        </div>
    </div>);
})