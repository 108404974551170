import React from 'react'
import './IconTag.scss'

interface IconTagProps {
    type: 'gift' | 'success'
    className?: string
}

export const IconTag: React.FC<IconTagProps> = (props) => {
    const {
        className,
        type,
    } = props

    const tagClasses = () => {
        let classes = ["wg-icon-tag"] as string[]
        if (type === 'success') classes.push('success')
        if (type === 'gift') classes.push('gift')
        if (className) classes.push(className)
        return classes.join(' ')
    }

    return (
        <div className={tagClasses()}>
            {
                type === 'success' &&
                <svg
                    width="10"
                    height="10"
                    xmlns="http://www.w3.org/2000/svg"
                    className="wg-icon-tag__icon"
                >
                    <path fillRule="evenodd" clipRule="evenodd" d="M9.4802 1.4239a.75.75 0 01.096 1.0563l-5 6a.75.75 0 01-1.0772.078l-3-2.6923a.75.75 0 011.002-1.1164l2.4209 2.1727 4.502-5.4023a.75.75 0 011.0563-.096z"/>
                </svg>
            }
            {
                type === 'gift' &&
                <svg
                    width="12"
                    height="12"
                    xmlns="http://www.w3.org/2000/svg"
                    className="wg-icon-tag__icon"
                >
                    <path d="M10.32 9.59995v-2.664c.1496-.11235.2705-.25479.3536-.4168.083-.162.1262-.33944.1264-.5192v-1.2c-.0004-.28524-.1092-.56102-.3068-.7779-.1976-.21688-.4712-.36067-.77165-.4056.10568-.27177.13994-.56342.09987-.85023-.04007-.28682-.15327-.56036-.33004-.79746-.17676-.23709-.41187-.43075-.68542-.56458-.27354-.13383-.57746-.20389-.88601-.20423-.3636.00062-.72289.07385-1.05395.21481-.33107.14096-.6263.34641-.86605.60269-.23975-.25628-.53498-.46173-.86604-.60269-.33106-.14096-.69036-.21419-1.05396-.21481-.30855.00034-.61247.0704-.88601.20423-.27355.13383-.50866.32749-.68542.56458-.17676.2371-.28997.51064-.33004.79746-.04007.28681-.00581.57846.09987.85023-.30046.04493-.57402.18872-.77164.4056-.19762.21688-.30637.49266-.30676.7779v1.2c.00012.17976.04332.3572.1264.5192.08308.16201.20392.30445.3536.4168v2.664c0 .31826.13486.62345.3749.84855.24005.225.56562.3515.9051.3515h6.08c.33948 0 .66505-.1265.9051-.3515.24005-.2251.37495-.53029.37495-.84855zm-6.24005-4.8h1.28v1.2h-2.88v-1.2h1.6zm2.56 0h2.88v1.2h-2.88v-1.2zm1.28-2.4c.16974 0 .33253.06322.45255.17574.12002.11252.18745.26513.18745.42426 0 .15913-.06743.31174-.18745.42427-.12002.11252-.28281.17573-.45255.17573h-1.28c0-.31826.13486-.62348.3749-.84853.24005-.22504.56562-.35147.9051-.35147zm-3.84 0c.33948 0 .66505.12643.9051.35147.24004.22505.3749.53027.3749.84853h-1.28c-.16974 0-.33252-.06321-.45255-.17573-.12002-.11253-.18745-.26514-.18745-.42427s.06743-.31174.18745-.42426c.12003-.11252.28281-.17574.45255-.17574zm-1.12 7.2v-2.4h2.4v2.4h-2.4zm3.68-2.4h2.4v2.4h-2.4v-2.4z"/>
                </svg>
            }
        </div>
    )
}