import {BaseModal, RadioBtn} from '@Modules'
import { rootState } from '@State'
import { onlyChildrenIdsDirection } from '@Utils'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useMemo } from 'react'
import './DirectionFilterMainModal.scss'

interface DirectionMainModalProps {
    isShow: boolean
    onClose: () => void
    onDeny: () => void
    onAccept: () => void
}

export const DirectionFilterMainModal: React.FC<DirectionMainModalProps> = observer((props) => {
    const {
        isShow,
        onClose,
        onAccept,
    } = props

    const mainPageState = rootState.mainPage
    const filter = rootState.filterMainPage
    const checkedDirections = filter.directionIds

    // const onlyChildrenIds = (dir: DirectionInStore) => getChildrenIds(dir).filter(id => id !== dir.id)

    const allDirectionsIds = useMemo(() => {
        return mainPageState.directionsInStore
            .map(dir => onlyChildrenIdsDirection(dir))
            .flat(Infinity) as number[]
    }, [mainPageState.directionsInStore])

    const isCheckedAllDirections = allDirectionsIds.length === filter.directionIds.length

    const handleChange = (directionId: number) => {
        if (directionId === 0) {
            // Checked all directions
            filter.setDirectionIds(allDirectionsIds)
            return null
        }

        const currentDirection = mainPageState.directionsInStore.find(d => d.id === directionId)
        if (!currentDirection) return null

        const directionChildrenIds = onlyChildrenIdsDirection(currentDirection)
        const isCheckedAllChildren = directionChildrenIds.every(id => checkedDirections.includes(id))

        if (!isCheckedAllChildren) {
            // Add directions

            // If all directions have been checked, check only one group
            // else concat new directions with old directions
            const prevChecked = isCheckedAllDirections ? [] : checkedDirections
            //
            const withDirection = [...prevChecked, ...directionChildrenIds]
            filter.setDirectionIds(withDirection)
            return null
        }

        if (isCheckedAllDirections) {
            filter.setDirectionIds(directionChildrenIds)
            return null
        }

        // Otherwise remove all children directions
        const withoutDirection = checkedDirections.filter(id => !directionChildrenIds.includes(id))
        if (!withoutDirection.length) {
            filter.setDirectionIds(allDirectionsIds)
            return null
        }
        filter.setDirectionIds(withoutDirection)
    }

    useEffect(() => {
        filter.setDirectionIds(allDirectionsIds)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mainPageState.directionsInStore])

    if (!mainPageState.groupSessions) return null

    if (mainPageState.isLoadingDirectionsInStore) return (
        <div className="wg-direction-line-main-page">
            {
                new Array(6).fill('').map((_, idx) => (
                    <div key={idx} className="wg-direction-line-main-page__item placeholder"></div>
                ))
            }
        </div>
    )

    const handleAccept = () => {
        filter.setDirectionIds(allDirectionsIds)
        onAccept()
    }

    return (
        <BaseModal
            isShow={isShow}
            onClose={onClose}
            timeout={300}
            className="wg-direction-filter-modal"
            onDeny={onClose}
            onAccept={handleAccept}
        >
            <form className="wg-direction-filter-modal__content">
            <RadioBtn
                type="checkbox"
                text="Все расписание"
                value={0}
                checked={isCheckedAllDirections}
                className="wg-direction-filter-modal__radio-item"
                onChange={e => handleChange(+e.target.value)}
            />
            {
                mainPageState.directionsInStore
                    .map(dir => {
                        const checkedAllChildren = onlyChildrenIdsDirection(dir).every(cId => checkedDirections.includes(cId))
                        return (
                            <RadioBtn
                                key={dir.id || Math.random()}
                                type="checkbox"
                                text={dir.name}
                                value={dir.id}
                                checked={
                                    checkedAllChildren &&
                                    !isCheckedAllDirections
                                }
                                className="wg-direction-filter-modal__radio-item"
                                onChange={e => handleChange(+e.target.value)}
                            />
                        )
                    })
            }
            </form>
        </BaseModal>
    )
})