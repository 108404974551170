import React from 'react'
import { SVGArrow, SVGPosition } from 'src/modules/common'
import './BtnCityFilter.scss'

interface BtnFilterSelectProps extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>{
    text: string
    className?: string
}

export const BtnCityFilter: React.FC<BtnFilterSelectProps> = (props) => {
    const {
        className,
        text,
        ...otherProps
    } = props

    return (
        <button
            className={className ? `${className} wg-city-filter-btn` : "wg-city-filter-btn"}
            {...otherProps}
        >
            <span className="wg-city-filter-btn__icon-position-wrapper">
                <SVGPosition className="wg-city-filter-btn__icon-position" />
            </span>
            <span className="wg-city-filter-btn__text">
                {text}
            </span>
            <SVGArrow className="wg-city-filter-btn__icon-arrow" />
        </button>
    )
}