import React from 'react'

interface SVGSearchProps {
    fill?: string
    className?: string
}

export const SVGSearch: React.FC<SVGSearchProps> = (props) => {
    const {
        fill = '#051932',
        className,
    } = props

    return (
        <svg
            width="16"
            height="16"
            fill={fill}
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path fillRule="evenodd" clipRule="evenodd" d="M11.325 12.343a6.48 6.48 0 111.018-1.018l2.646 2.646a.72.72 0 11-1.018 1.018l-2.646-2.646zm.995-5.063a5.04 5.04 0 11-10.08 0 5.04 5.04 0 0110.08 0z" />
        </svg>
    )
}