import { useEffect } from 'react'

export const useEscape = (callback: () => void) => {

    useEffect(() => {
        const handler = (e: KeyboardEvent) => {
            if (e.key === 'Escape') callback()
        }
        document.addEventListener('keydown', handler)
        return () => document.removeEventListener('keydown', handler)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
}