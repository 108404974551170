import { rootState } from "@State"
import { useEffect } from "react"
import { getStoreByIdFromCitites } from "src/utils/state"

export const useServerFilter = () => {
    /*
        The hook filters session groups by fetching
    */

    const mainPageState = rootState.mainPage
    const filter = rootState.filterMainPage

    const currentClub = getStoreByIdFromCitites(mainPageState.citiesWithStores, filter.clubId)

    useEffect(() => {
        if (filter.clubId && filter.dateFrom && filter.dateTo) {
            if (currentClub) {
                mainPageState.updateGroupSession(currentClub.storeGuid, filter.dateFrom, filter.dateTo)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter.clubId, filter.dateFrom, filter.dateTo])

    useEffect(() => {
        if (currentClub) {
            mainPageState.updateDirectionsInStore(currentClub.storeGuid)
            mainPageState.updatePersonnelsInStore(currentClub.id)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter.clubId])
}