import { DirectionInStore } from '@Types';
import { onlyChildrenIdsDirection } from '@Utils';
import { action, makeAutoObservable } from "mobx";
import { TimePeriodFilter } from "src/utils/state";

class FilterMainPageState {
    // City modal
    cityName: string = ''
    clubId: number = 0

    // Calendar
    viewDay: boolean = false
    dateFrom: string = ''
    dateTo: string = ''
    currentDate: number[] | null[] = [null, null]

    // Direction modal and direction-line
    directionIds: number[] = []
    coachesIds: number[] = []

    // Particular modal
    timePeriods: TimePeriodFilter = {}
    isPopular: boolean = false
    isPreRegistration: boolean = false
    isFreeFirstLesson: boolean = false
    isPaymentLesson: boolean = false

    constructor() {
        makeAutoObservable(
            this,
            {
                setCurrentDate: action.bound,
                setViewDay: action.bound,
                setCityName: action.bound,
                setClubId: action.bound,
                setDateFrom: action.bound,
                setDateTo: action.bound,
                setDirectionIds: action.bound,
                setCoachesIds: action.bound,
                setTimePeriod: action.bound,

                setIsPopular: action.bound,
                setIsPreRegistration: action.bound,
                setIsFreeFirstLesson: action.bound,
                setIsPaymentLesson: action.bound,
                resetFilter: action.bound,
            }
        )
    }

    resetFilter = (directions: DirectionInStore[]) => {
        const allDirectionsIds = directions
            .map(dir => onlyChildrenIdsDirection(dir))
            .flat(Infinity) as number[]

        // Direction modal and direction-line
        this.directionIds = allDirectionsIds
        this.coachesIds = []

        // Particular modal
        this.timePeriods = {}
        this.isPopular = false
        this.isPreRegistration = false
        this.isFreeFirstLesson = false
        this.isPaymentLesson = false
    }

    setCurrentDate = (week: number, date: number) => {
        this.currentDate = [week, date]
    }

    setViewDay = (val: boolean) => {
        this.viewDay = val;
    }

    setCityName = (val: string) => {
        this.cityName = val
    }

    setClubId = (val: number) => {
        this.clubId = val
    }

    setDateFrom = (val: string) => {
        this.dateFrom = val
    }

    setDateTo = (val: string) => {
        this.dateTo = val
    }

    setDirectionIds = (val: number[]) => {
        this.directionIds = val
    }

    setCoachesIds = (val: number[]) => {
        this.coachesIds = val
    }

    setTimePeriod = (val: TimePeriodFilter) => {
        this.timePeriods = val
    }

    setIsPopular = (val: boolean) => {
        this.isPopular = val
    }

    setIsPreRegistration = (val: boolean) => {
        this.isPreRegistration = val
    }

    setIsFreeFirstLesson = (val: boolean) => {
        this.isFreeFirstLesson = val
    }

    setIsPaymentLesson = (val: boolean) => {
        this.isPaymentLesson = val
    }
}

export const filterMainPageState = new FilterMainPageState()