import React, { useEffect, useRef, useState } from 'react'
import { SVGArrow } from '@Modules'
import './BaseAccordeon.scss'
import { onEnter } from '@Utils'

interface BaseAccordeonProps {
    header: JSX.Element
    body: JSX.Element
    className?: string
    tabIndex?: number
    showArrow?: boolean
}

export const BaseAccordeon: React.FC<BaseAccordeonProps> = (props) => {
    const {
        header,
        body,
        className,
        tabIndex = 1,
        showArrow = true,
    } = props

    const [open, setOpen] = useState<boolean>(false)
    const [bodyHeight, setBodyHeight] = useState<number>(0)
    const bodyWrapperRef = useRef<HTMLDivElement>(null)

    const handleClickHeader = () => {
        if (!open) {
            handleOpen()
        } else {
            handleClose()
        }
    }

    const handleClose = () => {
        setBodyHeight(0)
        setOpen(false)
    }

    const getBodyHeight = () => {
        return bodyWrapperRef.current?.scrollHeight || 0
    }

    const handleOpen = () => {
        if (!bodyWrapperRef.current) return null
        setBodyHeight(getBodyHeight())
        setOpen(true)
    }

    const wrapClasses = () => {
        const classes = ['wg-base-accordeon']
        if (className) classes.push(className)
        if (open) classes.push('opened')
        return classes.join(' ')
    }

    useEffect(() => {
        const resizer = () => {
            setBodyHeight(getBodyHeight())
        }
        window.addEventListener('resize', resizer)
        return () => {
            window.removeEventListener('resize', resizer)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bodyWrapperRef.current])

    return (
        <div className={wrapClasses()}>
            <div
                className="wg-base-accordeon__header"
                tabIndex={tabIndex}
                onClick={handleClickHeader}
                onKeyDown={onEnter(handleClickHeader)}
            >
                <div className="wg-base-accordeon__header-content">
                    {header}
                </div>
                {
                    showArrow &&
                    <SVGArrow className="wg-base-accordeon__header-arrow" />
                }
            </div>
            <div
                className="wg-base-accordeon__body-wrapper"
                ref={bodyWrapperRef}
                style={{
                    height: `${open ? bodyHeight : 0}px`
                }}
            >
                <div className="wg-base-accordeon__body">
                    {body}
                </div>
            </div>
        </div>
    )
}