import React from 'react'

interface SVGResetProps {
    className?: string
}

export const SVGReset: React.FC<SVGResetProps> = (props) => {
    const {
        className
    } = props

    return (
        <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg" fill="#E65A96" className={className}>
            <path fillRule="evenodd" clipRule="evenodd" d="M3 10a7 7 0 017-7 1 1 0 100-2 9 9 0 106.7 3H18a1 1 0 000-2h-4a1 1 0 00-1 1v4a1 1 0 102 0V5.1A7 7 0 113 10z" />
        </svg>
    )
}