import React from 'react'

interface SVGLikeProps {
    className?: string
}

export const SVGLike: React.FC<SVGLikeProps> = (props) => {
    const {
        className
    } = props

    return (
        <svg width="20" height="20" fill="#A3AAB4" xmlns="http://www.w3.org/2000/svg" className={className}>
            <path d="M16.286 7.714h-3.657a.571.571 0 01-.552-.718l.706-2.656a1.705 1.705 0 00-.49-1.424l-.746-.749a.576.576 0 00-.873.077l-3.9 5.571a1.128 1.128 0 00-.203.64v7.26c0 .63.512 1.142 1.143 1.142h5.143c.887 0 1.74-.426 2.286-1.143l2.743-3.656c.074-.099.114-.219.114-.344V9.43c0-.947-.768-1.715-1.714-1.715zM4.286 7.714H3.143C2.512 7.714 2 8.226 2 8.857v6.857c0 .632.512 1.143 1.143 1.143h1.143c.63 0 1.143-.511 1.143-1.143V8.857c0-.63-.512-1.143-1.143-1.143z" />
        </svg>
    )
}