import React from 'react'
import { calcHeight, useMessageInit, useMutationObserver } from '@Utils';
import { messageService } from '@Services';
import { observer } from 'mobx-react-lite';
import { AuthErrorModule } from 'src/modules/modals';
import { rootState } from '@State';

interface ScheduleItemModalProps {
}

export const AppLayout: React.FC<ScheduleItemModalProps> = observer((props) => {
    const {
        children
    } = props

    const handleMutation = () => {
        messageService.sendMessage('SITE_RESIZE', calcHeight(messageService.$container))
    }
    const { containerRef } = useMutationObserver<HTMLDivElement>(handleMutation)
    useMessageInit(containerRef)

    return (
        <div className="wg-app" ref={containerRef}>
            <div className="wg-container" id="wg-cont">
                {children}
            </div>
            {/**<AuthErrorModule
                isShow={rootState.auth.isErrorAuth}
                onClose={() => rootState.auth.setErrorAuth(false)}
            />**/}
        </div>
    )
})