import React, { useEffect, useState } from 'react'
import { BaseModal } from '@Modules'
import './ClubFilterModal.scss'
import { InputSelect } from 'src/modules/form'
import { SelectOption } from '@Types'
import { observer } from 'mobx-react-lite'
import { rootState } from '@State'

interface ScheduleItemModalProps {
    isShow: boolean
    onClose: () => void
    onAccept: () => void
}

export const ClubFilterModal = observer(((props: ScheduleItemModalProps) => {
    const {
        isShow,
        onClose,
        onAccept,
    } = props

    const mainPageState = rootState.mainPage
    const filter = rootState.filterMainPage

    const [selectedCity, setSelectedCity] = useState<SelectOption['value']>('')
    const [selectedClubId, setSelectedClubId] = useState<SelectOption['value']>('')

    const cityOptions = (): SelectOption[] => {
        return mainPageState.citiesWithStores.map(c => ({
            text: c.localityName,
            value: c.localityName,
        }))
    }

    const clubOptions = (): SelectOption[] => {
        const currentCity = mainPageState.citiesWithStores.find(c => c.localityName === selectedCity)
        let options: SelectOption[] = []
        if (currentCity) {
            options = currentCity.stores.map(s => ({
                text: s.name,
                value: s.id,
            }))
        }
        return options
    }

    const handleCity = (val: string | number) => {
        if (val === filter.cityName) return null
        setSelectedCity(val)
        setSelectedClubId('')
    }

    const handleApply = () => {
        filter.setCityName(selectedCity.toString())
        filter.setClubId(+selectedClubId || 0)
        if (!+selectedClubId) mainPageState.cleanGroupSession()
        onAccept()
        onClose()
    }

    useEffect(() => {
        // auto setting filter values to local state
        if (mainPageState.citiesWithStores) {
            setSelectedCity(filter.cityName)
            if (filter.cityName) {
                setSelectedClubId(filter.clubId)
            } else {
                setSelectedClubId('')
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mainPageState.citiesWithStores, filter.cityName])

    return (
        <BaseModal
            isShow={isShow}
            onClose={onClose}
            timeout={300}
            className="wg-club-filter-modal"
            customScroll={false}
            size="small"
        >
            <div className="wg-club-filter-modal__content">
                <p className="wg-club-filter-modal__title">Выбери клуб</p>

                <InputSelect
                    options={cityOptions()}
                    value={selectedCity}
                    placeholer="Город"
                    className="wg-club-filter-modal__select"
                    onChange={handleCity}
                    withReset={false}
                />

                <InputSelect
                    options={clubOptions()}
                    value={+selectedClubId}
                    disabled={!selectedCity}
                    placeholer="Клуб"
                    className="wg-club-filter-modal__select"
                    onChange={setSelectedClubId}
                    withReset={false}
                />

                <button
                    className="wg-club-filter-modal__accept wg-primary-btn"
                    onClick={handleApply}
                >
                    Выбрать
                </button>
            </div>
        </BaseModal>
    )
}))

