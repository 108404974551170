import React from 'react'

interface SVGPersonsProps {
    fill?: string
}

export const SVGPersons: React.FC<SVGPersonsProps> = (props) => {
    const {
        fill = '#00C0D1'
    } = props

    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill={fill} xmlns="http://www.w3.org/2000/svg">
            <path d="M9 6C9 6.79565 8.68393 7.55871 8.12132 8.12132C7.55871 8.68393 6.79565 9 6 9C5.20435 9 4.44129 8.68393 3.87868 8.12132C3.31607 7.55871 3 6.79565 3 6C3 5.20435 3.31607 4.44129 3.87868 3.87868C4.44129 3.31607 5.20435 3 6 3C6.79565 3 7.55871 3.31607 8.12132 3.87868C8.68393 4.44129 9 5.20435 9 6ZM17 6C17 6.39397 16.9224 6.78407 16.7716 7.14805C16.6209 7.51203 16.3999 7.84274 16.1213 8.12132C15.8427 8.3999 15.512 8.62087 15.1481 8.77164C14.7841 8.9224 14.394 9 14 9C13.606 9 13.2159 8.9224 12.8519 8.77164C12.488 8.62087 12.1573 8.3999 11.8787 8.12132C11.6001 7.84274 11.3791 7.51203 11.2284 7.14805C11.0776 6.78407 11 6.39397 11 6C11 5.20435 11.3161 4.44129 11.8787 3.87868C12.4413 3.31607 13.2044 3 14 3C14.7956 3 15.5587 3.31607 16.1213 3.87868C16.6839 4.44129 17 5.20435 17 6ZM13.9333 17C13.4034 17 13 16.5299 13 16C13.0017 14.8166 12.7023 13.659 12.1383 12.6327C11.8447 12.0984 11.9753 11.3879 12.5592 11.2122C13.0246 11.0722 13.5099 11.0001 14 11.0001C14.8776 11.0001 15.7399 11.2311 16.4999 11.67C17.26 12.1088 17.8912 12.74 18.3301 13.5C18.7689 14.2601 19 15.1223 19 16C19 16.5523 18.5523 17 18 17H13.9333ZM6 11C7.32608 11 8.59785 11.5268 9.53553 12.4645C10.4732 13.4021 11 14.6739 11 16C11 16.5523 10.5523 17 10 17H2C1.44772 17 1 16.5523 1 16C1 14.6739 1.52678 13.4021 2.46447 12.4645C3.40215 11.5268 4.67392 11 6 11Z" fill="#00C0D1" />
        </svg>
    )
}