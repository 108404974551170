import React, { useEffect, useState } from 'react'
import { BaseModal } from '@Modules'
import './CoachesFilterModal.scss'
import { InputFind } from '@Modules'
import { CoachCheckbox } from 'src/modules/form'
import { observer } from 'mobx-react-lite'
import { rootState } from '@State'
import { PersonnelInStore } from '@Types'

interface ScheduleItemModalProps {
    isShow: boolean
    onClose: () => void
    onDeny: () => void
    onAccept: () => void
}

export const CoachesFilterModal: React.FC<ScheduleItemModalProps> = observer((props) => {
    const {
        isShow,
        onClose,
        onDeny,
        onAccept,
    } = props

    const mainPageState = rootState.mainPage
    const filter = rootState.filterMainPage
    const coaches = mainPageState.personnelsInStore

    const [find, setFind] = useState<string>('')
    const [localCheckedIds, setLocalCheckedIds] = useState<number[]>(filter.coachesIds)

    const coachName = (coach: PersonnelInStore) => `${coach.firstName} ${coach.surname}`

    const filteredCoaches = find
        ? coaches.filter(coach => coachName(coach)
            .toLowerCase()
            .includes(find.toLowerCase()))
        : coaches

    const handleChange = (coachId: number) => {
        const coach = coaches.find(c => c.id === coachId)
        if (!coach) return null

        if (localCheckedIds.includes(coachId)) {
            setLocalCheckedIds(localCheckedIds.filter(id => id !== coachId))
            return null
        }

        setLocalCheckedIds([...localCheckedIds, coachId])
    }

    const handleAccept = () => {
        filter.setCoachesIds(localCheckedIds)
        onAccept()
    }

    useEffect(() => {
        coaches.forEach((coach) => {
            mainPageState.loadCoachProfile(coach.id)
        })
    }, [coaches]);

    useEffect(() => {
        if (isShow) {
            setLocalCheckedIds(filter.coachesIds)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isShow])

    return (
        <BaseModal
            isShow={isShow}
            onClose={onClose}
            timeout={300}
            className="wg-coaches-filter-modal"
            onDeny={onDeny}
            onAccept={handleAccept}
        >
            <div className="wg-coaches-filter-modal__content">
                <InputFind
                    value={find}
                    onChange={e => setFind(e.target.value)}
                />
                {
                    Boolean(filteredCoaches.length) &&
                    <div className="wg-coaches-filter-modal__coach-list">
                        {
                            filteredCoaches.map(coach => (
                                <CoachCheckbox
                                    key={coach.id}
                                    text={coachName(coach)}
                                    value={coach.id}
                                    profile={mainPageState.coachProfiles.find(ch => ch.id === coach.id)}
                                    checked={localCheckedIds.includes(coach.id)}
                                    className="wg-coaches-filter-modal__coach-item"
                                    onChange={e => handleChange(+e.target.value)}
                                />
                            ))
                        }
                    </div>
                }
                {
                    !Boolean(filteredCoaches.length) &&
                    <p className="wg-coaches-filter-modal__empty-text">
                        По запросу "<b>{find}</b>" инструкторы не найдены
                    </p>
                }
            </div>
        </BaseModal>
    )
})