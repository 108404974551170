import React, { useState } from 'react'
import './SquareCheckbox.scss'

interface SquareCheckboxProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
    className?: string
}

export const SquareCheckbox: React.FC<SquareCheckboxProps> = (props) => {
    const {
        className,
        ...inputProps
    } = props

    const [focused, setFocused] = useState(false)

    const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (inputProps.onKeyUp) inputProps.onKeyUp(e)
        if (e.key === 'Tab') setFocused(true)
    }

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (inputProps.onKeyDown) inputProps.onKeyDown(e)
        if (e.key === 'Tab') setFocused(false)
    }

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        if (inputProps.onBlur) inputProps.onBlur(e)
        setFocused(false)
    }

    const wrapClasses = () => {
        const classes = ["wg-square-checkbox"]
        if (className) classes.push(className)
        if (focused) classes.push('focused')
        return classes.join(' ')
    }

    return (
        <div className={wrapClasses()}>
            <label className="wg-square-checkbox__label">
                <input
                    type="checkbox"
                    className="wg-square-checkbox__input"
                    {...inputProps}
                    onKeyUp={handleKeyUp}
                    onKeyDown={handleKeyDown}
                    onBlur={handleBlur}
                />
                <span className="wg-square-checkbox__fake-input"></span>
            </label>
        </div>
    )
}