import { SVGCalendarOff } from '@Modules'
import { rootState } from '@State'
import { observer } from 'mobx-react-lite'
import React from 'react'
import './EmptyDataMainPage.scss'

interface EmptyDataMainPageProps {
    isShow: boolean,
    onClickClub: () => void
}

export const EmptyDataMainPage: React.FC<EmptyDataMainPageProps> = observer((props) => {
    const {
        isShow,
        onClickClub
    } = props

    const isSelectedClub = rootState.filterMainPage.clubId

    const emptyText = () => {
        if (!isSelectedClub){
            return <>
                Выберите клуб для получения занятий<br/>
                <button
                    className="wg-primary-btn"
                    onClick={onClickClub}
                >
                    Выбрать
                </button>
            </>;
        }
        return 'На выбранные даты занятий нет,\nВыберите другой день\nили измените параметры поиска'
    }

    if (!isShow) return null

    return (
        <div className="wg-empty-data-main-page">
            <SVGCalendarOff className="wg-empty-data-main-page__icon" />
            <p className="wg-empty-data-main-page__text">
                {emptyText()}
            </p>
        </div>
    )
})