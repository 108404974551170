import React, { useEffect, useMemo, useState } from 'react'
import { BaseModal } from '@Modules'
import './DirectionFilterModal.scss'
import { RadioBtn } from 'src/modules/form'
import { observer } from 'mobx-react-lite'
import { rootState } from '@State'
import { DirectionInStore } from '@Types'
import { getChildrenDirections, isCheckedEveryDirection, onlyChildrenIdsDirection } from '@Utils'

interface ScheduleItemModalProps {
    isShow: boolean
    onClose: () => void
    onDeny: () => void
    onAccept: () => void
}

export const DirectionFilterModal: React.FC<ScheduleItemModalProps> = observer((props) => {
    const {
        isShow,
        onClose,
        onAccept,
    } = props

    const mainPageState = rootState.mainPage
    const filter = rootState.filterMainPage

    const [localCheckedDirections, setLocalCheckedDirections] = useState(filter.directionIds)

    // const onlyChildrenIds = (dir: DirectionInStore) => getChildrenIds(dir).filter(id => id !== dir.id)

    const allDirectionsIds = useMemo(() => {
        return mainPageState.directionsInStore
            .map(dir => onlyChildrenIdsDirection(dir))
            .flat(Infinity) as number[]
    }, [mainPageState.directionsInStore])

    const allDirections = useMemo(() => {
        return mainPageState.directionsInStore
            .map(dir => getChildrenDirections(dir))
            .flat(Infinity) as DirectionInStore[]
    }, [mainPageState.directionsInStore])

    const isCheckedAllDirections = allDirectionsIds.length === localCheckedDirections.length

    const changedEveryDirection = () => {
        // Checked all directions
        setLocalCheckedDirections(allDirectionsIds)
        return null
    }

    const changedGroupOfDirections = (groupId: number) => {
        // const currentDirection = allDirections.find(d => d.id === groupId)
        const currentDirection = mainPageState.directionsInStore.find(d => d.id === groupId)

        if (!currentDirection) return null

        const directionChildrenIds = onlyChildrenIdsDirection(currentDirection)
        const isCheckedAllChildren = directionChildrenIds.every(id => localCheckedDirections.includes(id))

        if (!isCheckedAllChildren) {
            // Add directions

            // If all directions have been checked, check only one group
            // else concat new directions with old directions
            let prevChecked = isCheckedAllDirections ? [] : localCheckedDirections
            //

            // clear duplication if some directions of the group have been checked
            prevChecked = prevChecked.filter(id => !directionChildrenIds.includes(id))

            const withDirection = [...prevChecked, ...directionChildrenIds]
            setLocalCheckedDirections(withDirection)
            return null
        }

        if (isCheckedAllDirections) {
            setLocalCheckedDirections(directionChildrenIds)
            return null
        }

        // Otherwise remove all children directions
        const withoutDirection = localCheckedDirections.filter(id => !directionChildrenIds.includes(id))
        if (!withoutDirection.length) {
            setLocalCheckedDirections(allDirectionsIds)
            return null
        }
        setLocalCheckedDirections(withoutDirection)
    }

    const changedOneDirection = (directionId: number, groupId: number) => {
        const currentDirection = allDirections.find(d => d.id === directionId)
        const parentDirection = allDirections.find(d => d.id === groupId)
        if (!currentDirection || !parentDirection) return null

        const childrenOfGroupIds = onlyChildrenIdsDirection(parentDirection)
        const isCheckedGroup = childrenOfGroupIds.every(id => localCheckedDirections.includes(id))

        if (isCheckedEveryDirection(mainPageState.directionsInStore, localCheckedDirections.length)) {
            // if every direction chacked and we check some direction
            setLocalCheckedDirections([directionId])
            return null
        }

        if (isCheckedGroup) {
            // if check one direction in the group when group is checked
            const withoutGroupIds = localCheckedDirections.filter(id => !childrenOfGroupIds.includes(id))
            setLocalCheckedDirections([...withoutGroupIds, directionId])
            return null
        }

        if (localCheckedDirections.includes(directionId)) {
            const newDirections = localCheckedDirections.filter(id => id !== directionId)
            if (newDirections.length === 0) {
                changedEveryDirection()
                return null
            }
            setLocalCheckedDirections(newDirections)
        } else {
            const newDirections = [...localCheckedDirections, directionId]
            if (childrenOfGroupIds.every(id => newDirections.includes(id))) {
                /// if checked all children of group
                changedGroupOfDirections(groupId)
                return null
            }
            setLocalCheckedDirections(newDirections)
        }
    }

    const handleChange = (directionId: string) => {
        if (directionId.includes('every')) {
            changedEveryDirection()
            return null
        }

        if (directionId.includes('group')) {
            const id = +directionId.split('-')[0]

            changedGroupOfDirections(id)
            return null
        }

        if (directionId.includes('one')) {
            const [oneId, groupId] = directionId
                .split('-')
                .filter(c => !Number.isNaN(c))
                .map(c => +c)
            changedOneDirection(oneId, groupId)

            return null
        }
    }

    const handleAccept = () => {
        filter.setDirectionIds(localCheckedDirections)
        onAccept()
    }

    useEffect(() => {
        if (isShow) {
            setLocalCheckedDirections(filter.directionIds)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isShow])

    return (
        <BaseModal
            isShow={isShow}
            onClose={onClose}
            timeout={300}
            className="wg-direction-filter-modal"
            onDeny={onClose}
            onAccept={handleAccept}
        >
            <form className="wg-direction-filter-modal__content">
                <RadioBtn
                    text="Все направления"
                    type="checkbox"
                    value="every"
                    checked={isCheckedAllDirections}
                    className="wg-direction-filter-modal__radio-item"
                    onChange={e => handleChange(e.target.value)}
                />
                {
                    mainPageState.directionsInStore
                        .map(parentDir => {
                            const isCheckedAllChildrenOfGroup = onlyChildrenIdsDirection(parentDir).every(cId => localCheckedDirections.includes(cId))
                            return (
                                <div
                                    className="wg-direction-filter-modal__group"
                                    key={parentDir.id}
                                >
                                    <p className="wg-direction-filter-modal__group-title">
                                        {parentDir.name}
                                    </p>
                                    <RadioBtn
                                        type="checkbox"
                                        text="Все"
                                        value={`${parentDir.id}-group`}
                                        checked={
                                            isCheckedAllChildrenOfGroup &&
                                            !isCheckedAllDirections
                                        }
                                        className="wg-direction-filter-modal__radio-item"
                                        onChange={e => handleChange(e.target.value)}
                                    />
                                    {
                                        parentDir.children.map(dir => {

                                            return (
                                                <RadioBtn
                                                    key={dir.id}
                                                    type="checkbox"
                                                    text={dir.name}
                                                    value={`${dir.id}-${parentDir.id}-one`}
                                                    checked={
                                                        localCheckedDirections.includes(dir.id) &&
                                                        !isCheckedAllChildrenOfGroup &&
                                                        !isCheckedAllDirections
                                                    }
                                                    className="wg-direction-filter-modal__radio-item"
                                                    onChange={e => handleChange(e.target.value)}
                                                />
                                            )
                                        })
                                    }
                                </div>
                            )
                        })
                }
            </form>
        </BaseModal>
    )
})