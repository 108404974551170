import React from 'react'
import { BaseModal } from '@Modules'
import './AuthErrorModule.scss'
import { observer } from 'mobx-react-lite'

interface AuthErrorModuleProps {
    isShow: boolean
    onClose: () => void
}

export const AuthErrorModule: React.FC<AuthErrorModuleProps> = observer((props) => {
    const {
        isShow,
        onClose,
    } = props

    return (
        <BaseModal
            isShow={isShow}
            onClose={onClose}
            timeout={300}
            className="wg-auth-error-modal enter"
        >
            <div className="wg-auth-error-modal__content">
                <p className="wg-auth-error-modal__text">
                    {`Возникла ошибка авторизации\n попробуйте обновить страницу`}
                </p>
            </div>
        </BaseModal>
    )
})