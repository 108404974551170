import { Route, Switch, withRouter } from 'react-router-dom';
import './App.scss';
import { observer } from 'mobx-react-lite'
import { rootState } from '@State'
import { useEffect } from 'react';
import { AppLayout, CircleLoader } from '@Modules';
import { MainPage } from '@Pages';


function App() {
    const authState = rootState.auth
    const mainPageState = rootState.mainPage

    useEffect(() => {
        (async function () {
            await authState.login()
            await mainPageState.updateCitiesWithStore()
        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (authState.authorized === null || !mainPageState.isInitializedStores) return <CircleLoader className="wg-app__auth-loader"/>

    return (
        <AppLayout>
            <Switch>
                <Route path="/mob" component={MainPage}/>
                <Route path="/" component={MainPage}/>
            </Switch>
        </AppLayout>
    );
}

export default withRouter(observer(App));
