import { CircleLoader, ScheduleCard } from '@Modules'
import { rootState } from '@State'
import { GroupSessionsPeriod, SessionsGroupSession } from '@Types'
import { observer } from 'mobx-react-lite'
import moment from 'moment-timezone'
import React, { useMemo } from 'react'
import { directionOfSession, periodOfSession, roomOfSession } from '../../utils/utils'
import './CardGroupMainPage.scss'

interface CardGroupMainPageProps {
    onClickCard: (session: SessionsGroupSession) => void
}

export const CardGroupMainPage: React.FC<CardGroupMainPageProps> = observer((props) => {
    const {
        onClickCard,
    } = props

    const mainPageState = rootState.mainPage
    const filter = rootState.filterMainPage
    const filteredItems = mainPageState.groupSessionsFiltered
    const sessions = filteredItems?.groupSessions
    console.log(mainPageState.directionsInStore);
    const groupSessionByStartTime = useMemo(() => {
        const sessionsByTime = {} as { [key: string]: GroupSessionsPeriod['groupSessions'] }
        if (!sessions) return {}
        console.log(sessions, sessionsByTime)
        sessions.forEach(s => {
            console.log(s);
            let dateStart;
            if(!filter.viewDay) {
                dateStart = moment(s.start).format('YYYY-MM-DD')
            }else{
                dateStart = moment(s.start).format('HH:mm')
            }
            if (!sessionsByTime[dateStart]) {
                sessionsByTime[dateStart] = []
            }
            sessionsByTime[dateStart].push(s)
        })
        return sessionsByTime
    }, [sessions])

    const sortStartTimes = (times: string[]) => {
        return [...times].sort((a, b) => {
            const [aHours, aMinutes] = a.split(':').map(str => +str)
            const [bHours, bMinutes] = b.split(':').map(str => +str)
            if (aHours === bHours) return aMinutes - bMinutes
            return aHours - bHours
        })
    }

    const startTimes = sortStartTimes(Object.keys(groupSessionByStartTime))
    console.log(groupSessionByStartTime);

    const coachNamesOfSession = (session: SessionsGroupSession) => {
        const coaches = mainPageState.personnelsInStore.filter(per => session.personnelId.includes(per.id))
        return coaches?.map(coach => `${coach.firstName} ${coach.surname}`).join('\n')
    }

    const groupClasses = () => {
        const classes = ['wg-main-page-card-group']
        if (mainPageState.isLoadingGroupSessions) classes.push('loading')
        return classes.join(' ')
    }

    if (mainPageState.isLoadingGroupSessions && !sessions?.length) {
        return (
            <CircleLoader className="wg-main-page-card-group-loader" />
        )
    }

    const parseDD = (date: string) => {
        const dateTime = date.split(' ');
        const dateDate = dateTime[0].split('-');
        switch (dateDate[1]){
            case "01": return dateDate[2]+' Января';
            case "02": return dateDate[2]+' Февраля';
            case "03": return dateDate[2]+' Марта';
            case "04": return dateDate[2]+' Апреля';
            case "05": return dateDate[2]+' Мая';
            case "06": return dateDate[2]+' Июня';
            case "07": return dateDate[2]+' Июля';
            case "08": return dateDate[2]+' Августа';
            case "09": return dateDate[2]+' Сентября';
            case "10": return dateDate[2]+' Октября';
            case "11": return dateDate[2]+' Ноября';
            case "12": return dateDate[2]+' Декабря';
        }
    }

    const parseDate = (date: string, idx: number) => {
        const dd = parseDD(date);
        if(idx > 0){
            if(dd === parseDD(startTimes[idx-1])){
                return null;
            }else{
                return dd;
            }
        }else{
            return dd;
        }
    }

    const parseTime = (date: string) => {
        const dateTime = date.split(' ');
        return dateTime[1];
    }

    return (
        <>
            {
                startTimes.map((start, idx) => (
                    <div className={groupClasses()} key={Math.random()}>
                        {!filter.viewDay && parseDate(start, idx) ? <p className="wg-main-page-card-group__title">
                            {parseDate(start, idx)}
                        </p>: null}
                        {filter.viewDay && <p className="wg-main-page-card-group__title">
                            {start}
                        </p>}
                        <div className="wg-main-page-card-group__schedule-items">
                            {
                                groupSessionByStartTime[start].map(session => (
                                    <ScheduleCard
                                        key={session.id || Math.random()}
                                        className="wg-main-page-card-group__schedule-item"
                                        title={session.name}
                                        direction={directionOfSession(session, filteredItems)?.name || ''}
                                        room={roomOfSession(session, filteredItems)?.name || ''}
                                        coachName={coachNamesOfSession(session) || ''}
                                        date={periodOfSession(session, moment(session.start).format("HH:mm"))}
                                        busyPlaces={session.registeredNumberParticipants}
                                        maxPlaces={session.maximumNumberParticipants}
                                        isNovelty={session.novelty}
                                        isFree={session.free}
                                        isCancel={session.cancelReason !== null}
                                        onClick={() => onClickCard(session)}
                                    />)
                                )
                            }
                        </div>
                    </div>
                ))
            }
        </>
    )
})