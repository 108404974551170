import React from 'react'

interface SVGCallTimeProps {
    className?: string
}

export const SVGCallTime: React.FC<SVGCallTimeProps> = (props) => {
    const {
        className
    } = props

    return (
        <svg width="20" height="20" fill="#A3AAB4" xmlns="http://www.w3.org/2000/svg" className={className}>
            <path d="M10.7 2a7.25 7.25 0 00-6.27 3.62 2.1 2.1 0 011.45.64 5.67 5.67 0 0110.5 3.01 5.67 5.67 0 01-2.69 4.84c.29.27.49.6.59.97l.07.47a7.25 7.25 0 003.62-6.28C18 5.27 14.73 2 10.7 2z" />
            <path d="M13.67 7.14c-.3-.3-.8-.3-1.12 0l-2.3 2.29L9.2 8.36c-.3-.3-.8-.3-1.12 0-.3.3-.3.81 0 1.13l1.63 1.64a.8.8 0 00.56.23c.2 0 .4-.08.55-.23l2.86-2.87c.3-.32.3-.8 0-1.12zM12.61 14.97l-2.25-1.17a.73.73 0 00-.93.2l-.83 1.07a8.35 8.35 0 01-3.68-3.7l1.04-.83c.29-.22.37-.6.2-.93L5 7.37a.75.75 0 00-1.08-.27l-1.43.96c-.33.22-.52.6-.49 1.01.04.4.09.93.17 1.28A10.75 10.75 0 005 15a10.8 10.8 0 004.67 2.83c.35.1.87.15 1.28.17.4.03.79-.17 1-.5l.93-1.46a.75.75 0 00-.27-1.07z" />
        </svg>
    )
}