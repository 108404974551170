import React, {useEffect, useState} from 'react'
import './MainPage.scss'
import { ScheduleItemModal, DirectionFilterModal, CoachesFilterModal, ParticularFilterModal } from '@Modules';
import { SessionsGroupSession } from '@Types';
import { rootState } from '@State';
import { observer } from 'mobx-react-lite';
import { CalendarMainPage } from './components/CalendarMainPage/CalendarMainPage';
import { useServerFilter } from './hooks/useServerFilter';
import { useClientFilter } from './hooks/useClientFilter';
import { useMountFilter } from './hooks/useMountFilter';
import { useSearchUpdater } from './hooks/useSearchUpdater';
import { ClubFilterModal } from './components/ClubFilterModal/ClubFilterModal';
import { CardGroupMainPage } from './components/CardGroupMainPage/CardGroupMainPage';
import { DirectionLineMainPage } from './components/DirectionLineMainPage/DirectionLineMainPage';
import { SelectLineMainPage } from './components/SelectLineMainPage/SelectLineMainPage';
import { EmptyDataMainPage } from './components/EmptyDataMainPage/EmptyDataMainPage';
import { HeaderLineMainPage } from './components/HeaderLineMainPage/HeaderLineMainPage';
import { ChangeView } from "../../modules/details/components/ChangeView/ChangeView";
import { useLocation } from "react-router-dom";
import {DirectionFilterMainModal} from "../../modules/modals/components/DirectionFilterMainModal/DirectionFilterMainModal";

interface MainPageProps { }

export const MainPage: React.FC<MainPageProps> = observer(() => {

    const mainPageState = rootState.mainPage
    let location = useLocation()

    // Modals
    const [showScheduleItem, setShowScheduleItem] = useState(false)
    const [showDirectionMainFilter, setShowDirectionMainFilter] = useState(false)
    const [showDirectionFilter, setShowDirectionFilter] = useState(false)
    const [showCoachesFilter, setShowCoachesFilter] = useState(false)
    const [showParticularFilter, setShowParticularFilter] = useState(false)
    const [showClubFilter, setShowClubFilter] = useState(false)

    const handleClickScheduleCard = (session: SessionsGroupSession) => {
        mainPageState.loadSessionInfo(session.guid)

        mainPageState.setSessionInModal(session)
        session.personnelId.forEach(id => {
            mainPageState.loadCoachProfile(id)
        })
        setShowScheduleItem(true)
    }

    useSearchUpdater()
    useMountFilter()
    useServerFilter()
    useClientFilter()

    return (
        <div className="wg-main-page">
            <HeaderLineMainPage
                onClickClub={() => setShowClubFilter(true)}
            />

            {!location.pathname.includes('mob') && <DirectionLineMainPage />}

            <SelectLineMainPage
                onClickCoach={() => setShowCoachesFilter(true)}
                onClickDirection={() => setShowDirectionFilter(true)}
                onClickParticular={() => setShowParticularFilter(true)}
                onClickDirectionMain={() => setShowDirectionMainFilter(true)}
                isMob={location.pathname.includes('mob')}
            />

            <CalendarMainPage />

            <ChangeView />

            <CardGroupMainPage
                onClickCard={session => handleClickScheduleCard(session)}
            />

            <EmptyDataMainPage
                isShow={
                    !Boolean(mainPageState.groupSessionsFiltered?.groupSessions?.length) &&
                    !mainPageState.isLoadingGroupSessions
                }
                onClickClub={() => setShowClubFilter(true)}
            />

            <ScheduleItemModal
                isShow={showScheduleItem}
                onClose={() => setShowScheduleItem(false)}
            />

            <DirectionFilterMainModal
                isShow={showDirectionMainFilter}
                onClose={() => setShowDirectionMainFilter(false)}
                onDeny={() => setShowDirectionMainFilter(false)}
                onAccept={() => setShowDirectionMainFilter(false)}
            />

            <DirectionFilterModal
                isShow={showDirectionFilter}
                onClose={() => setShowDirectionFilter(false)}
                onAccept={() => setShowDirectionFilter(false)}
                onDeny={() => setShowDirectionFilter(false)}
            />

            <CoachesFilterModal
                isShow={showCoachesFilter}
                onClose={() => setShowCoachesFilter(false)}
                onAccept={() => setShowCoachesFilter(false)}
                onDeny={() => setShowCoachesFilter(false)}
            />

            <ParticularFilterModal
                isShow={showParticularFilter}
                onClose={() => setShowParticularFilter(false)}
                onAccept={() => setShowParticularFilter(false)}
                onDeny={() => setShowParticularFilter(false)}
            />

            <ClubFilterModal
                isShow={showClubFilter}
                onClose={() => setShowClubFilter(false)}
                onAccept={() => setShowClubFilter(false)}
            />
        </div>
    )
})