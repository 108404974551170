import React from 'react'
import './BtnIcon.scss'

interface BtnIconProps extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>{
    Icon: JSX.Element
    text: string
    className?: string
}

export const BtnIcon: React.FC<BtnIconProps> = (props) => {
    const {
        className,
        Icon,
        text,
        ...btnProps
    } = props

    return (
        <button
            className={className ? `${className} wg-btn-icon` : "wg-btn-icon"}
            {...btnProps}
        >
            {Icon}
            <span className="wg-btn-icon__text">
                {text}
            </span>
        </button>
    )
}