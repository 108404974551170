import React, { useEffect, useState } from 'react'
import { BaseModal } from '@Modules'
import './ParticularFilterModal.scss'
import { TimeCheckbox, SVGCallTime, SVGGift, SVGLike, SVGRub, SwitchIconText } from '@Modules'
import { rootState } from '@State'
import { observer } from 'mobx-react-lite'
import moment from 'moment-timezone'
import { TimePeriodFilter } from 'src/utils/state'

interface ScheduleItemModalProps {
    isShow: boolean
    onClose: () => void
    onDeny: () => void
    onAccept: () => void
}

export const ParticularFilterModal: React.FC<ScheduleItemModalProps> = observer((props) => {
    const {
        isShow,
        onClose,
        onDeny,
        onAccept,
    } = props

    const filter = rootState.filterMainPage

    const [localPeriod, setLocalPeriod] = useState<TimePeriodFilter>(filter.timePeriods)

    const [localIsPopular, setLocalIsPopular] = useState<boolean>(filter.isPopular)
    const [localIsPreRegistation, setLocalIsPreRegistation] = useState<boolean>(filter.isPreRegistration)
    const [localIsFreeFirst, setLocalIsFreeFirst] = useState<boolean>(filter.isFreeFirstLesson)
    const [localIsPaymentLesson, setLocalIsPaymentLesson] = useState<boolean>(filter.isPaymentLesson)


    const utcDate = (hours = 0, minutes = 0, seconds = 0) => {
        return moment()
            .hours(hours)
            .minutes(minutes)
            .seconds(seconds)
            .utc()
            .format()
    }

    const handlePeriod = (periodKey: string) => {
        const newPeriods = { ...localPeriod }
        switch (periodKey) {
            case "morning": {
                if (!localPeriod.morning) {
                    newPeriods.morning = [utcDate(10), utcDate(11, 59, 59)]
                } else {
                    delete newPeriods.morning
                }
                break;
            }
            case "afternoon": {
                if (!localPeriod.afternoon) {
                    newPeriods.afternoon = [utcDate(12), utcDate(16, 59, 59)]
                } else {
                    delete newPeriods.afternoon
                }
                break;
            }
            case "evening": {
                if (!localPeriod.evening) {
                    newPeriods.evening = [utcDate(17), utcDate(22, 59, 59)]

                } else {
                    delete newPeriods.evening
                }
                break;
            }
        }
        setLocalPeriod(newPeriods)
    }

    const handleAccept = () => {
        filter.setTimePeriod(localPeriod)
        filter.setIsPopular(localIsPopular)
        filter.setIsPreRegistration(localIsPreRegistation)
        filter.setIsFreeFirstLesson(localIsFreeFirst)
        filter.setIsPaymentLesson(localIsPaymentLesson)
        onAccept()
    }

    useEffect(() => {
        if (isShow) {
            setLocalPeriod(filter.timePeriods)
            setLocalIsPopular(filter.isPopular)
            setLocalIsPreRegistation(filter.isPreRegistration)
            setLocalIsFreeFirst(filter.isFreeFirstLesson)
            setLocalIsPaymentLesson(filter.isPaymentLesson)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isShow])


    return (
        <BaseModal
            isShow={isShow}
            onClose={onClose}
            timeout={300}
            className="wg-particular-filter-modal"
            onDeny={onDeny}
            onAccept={handleAccept}
        >
            <div className="wg-particular-filter-modal__content">
                <p className="wg-particular-filter-modal__title">
                    Особености
                </p>
                <div className="wg-particular-filter-modal__time-block">
                    <p className="wg-particular-filter-modal__time-title">
                        Удобное время
                    </p>
                    <div className="wg-particular-filter-modal__time-options">
                        {
                            times.map(t => (
                                <TimeCheckbox
                                    title={t.title}
                                    descr={t.descr}
                                    key={t.val}
                                    checked={localPeriod.hasOwnProperty(t.val)}
                                    onChange={() => handlePeriod(t.val)}
                                    className="wg-particular-filter-modal__time-option"
                                />
                            ))
                        }
                    </div>
                </div>
                <div className="wg-particular-filter-modal__switch-block">
                    <SwitchIconText
                        text="Популярное"
                        Icon={SVGLike}
                        checked={localIsPopular}
                        className="wg-particular-filter-modal__switch-item"
                        onChange={() => setLocalIsPopular(!localIsPopular)}
                    />
                    <SwitchIconText
                        text="Предварительная запись"
                        Icon={SVGCallTime}
                        checked={localIsPreRegistation}
                        className="wg-particular-filter-modal__switch-item"
                        onChange={() => setLocalIsPreRegistation(!localIsPreRegistation)}
                    />
                    <SwitchIconText
                        text="Бесплатное пробное занятие"
                        Icon={SVGGift}
                        checked={localIsFreeFirst}
                        className="wg-particular-filter-modal__switch-item"
                        onChange={() => setLocalIsFreeFirst(!localIsFreeFirst)}
                    />
                    <SwitchIconText
                        text="Платное занятие"
                        Icon={SVGRub}
                        checked={localIsPaymentLesson}
                        className="wg-particular-filter-modal__switch-item"
                        onChange={() => setLocalIsPaymentLesson(!localIsPaymentLesson)}
                    />
                </div>
            </div>
        </BaseModal>
    )
})

const times = [
    {
        title: 'Утро',
        descr: '10:00-12:00',
        val: 'morning',
    },
    {
        title: 'День',
        descr: '12:00-17:00',
        val: 'afternoon',
    },
    {
        title: 'Вечер',
        descr: '17:00-23:00',
        val: 'evening',
    },
]