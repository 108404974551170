import { DirectionInStore } from "@Types"

type GetChildrenIdsObj = {
    id: number
    children: GetChildrenIdsObj[]
}

export const sleep = async(ms: number) => new Promise((res) => setTimeout(res, ms))

export const getChildrenIds = (obj: GetChildrenIdsObj, maxDepth = 0): number[] => {
    let depth = 0

    const calc = (obj: GetChildrenIdsObj): number[] => {
        if (!obj) return []
        const children = obj.children
        if (children.length && depth <= maxDepth) {
            depth++
            const childrenIds = children.map(c => calc(c))
            return [obj.id, ...childrenIds].flat(Infinity) as number[]
        }
        return [obj.id]
    }
    return calc(obj)
}

export const getChildrenDirections = (dir: DirectionInStore): DirectionInStore[] => {
    if (!dir) return []
    const children = dir.children
    if (children.length) {
        const childrenDirs = children.map(c => getChildrenDirections(c))
        return [dir, ...childrenDirs].flat(Infinity) as DirectionInStore[]
    }
    return [dir]
}

export const isCheckedEveryDirection = (directions: DirectionInStore[], currentCheckedLength: number) => {
    let checkedLength = 0
    directions.forEach(dir => {
        checkedLength +=  getChildrenIds(dir).length
    })
    return checkedLength === currentCheckedLength
}

export const minutesParser = (val: number) => {
    const lastTwoNumbers = +(val + '').slice(-2)
    const lastNumber = +(val + '').slice(-1)
    let baseWord = 'минут'

    if (lastTwoNumbers >= 5 && lastTwoNumbers <= 20) return baseWord
    if (lastNumber >= 2 && lastNumber <= 4) return `${baseWord}ы`
    if (lastNumber === 1) return `${baseWord}а`
    if (lastNumber === 0) return baseWord

    return baseWord
}

export const onlyChildrenIdsDirection = (dir: DirectionInStore) => getChildrenIds(dir)
