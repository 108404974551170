import React from 'react'

interface SVGRubProps {
    className?: string
}

export const SVGRub: React.FC<SVGRubProps> = (props) => {
    const {
        className
    } = props

    return (
        <svg width="20" height="20" fill="#A3AAB4" xmlns="http://www.w3.org/2000/svg" className={className}>
            <path fillRule="evenodd" clipRule="evenodd" d="M8 13v-1.63h2.95c1.9 0 3.38-.4 4.44-1.2A4.06 4.06 0 0017 6.7c0-1.53-.54-2.7-1.61-3.5C14.33 2.4 12.85 2 10.95 2H5.43a.5.5 0 00-.5.5V13H3.5a.5.5 0 00-.5.5v1.13c0 .28.22.5.5.5h1.43v2.37c0 .28.22.5.5.5H7.5a.5.5 0 00.5-.5v-2.37h3.97a.5.5 0 00.5-.5V13.5a.5.5 0 00-.5-.5H8zm5-7.84c.5.36.74.88.74 1.55 0 .7-.25 1.22-.74 1.6a3.4 3.4 0 01-2.07.53H8V4.63h2.93c.9 0 1.6.17 2.07.53z" />
        </svg>
    )
}