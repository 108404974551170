import { Calendar } from '@Modules'
import { rootState } from '@State'
import { observer } from 'mobx-react-lite'
import moment from 'moment-timezone'
import React, {useEffect, useState} from 'react'

interface CalendarMainPageProps {}

export const CalendarMainPage: React.FC<CalendarMainPageProps> = observer(() => {

    const filterMain = rootState.filterMainPage

    const [load, setLoad] = useState(false);

    useEffect(() => {
        const onLoad = () => {
            setTimeout(() => {
                setLoad(true);
            },300);
        }

        window.addEventListener('load', onLoad)

        return () => window.removeEventListener('load', onLoad)
    }, []);

    const handleCurrentCallback = (week: number, date: number) => {
        filterMain.setCurrentDate(week, date);
    }

    const handleCalendar = (_: string, date: string) => {
        console.log(date, _)
        if(date) {
            const dateFrom = moment(date).format()
            let dateTo: string = '';
            if (filterMain.viewDay) {
                dateTo = moment(date)
                    .hours(23)
                    .minutes(59)
                    .seconds(59)
                    .format()
            } else {
                dateTo = moment(date).add(6, 'days')
                    .hours(23)
                    .minutes(59)
                    .seconds(59)
                    .format()
            }
            console.log(dateFrom, dateTo)
            filterMain.setDateFrom(dateFrom)
            filterMain.setDateTo(dateTo)
        }
    }

    return (
        <Calendar
            isTriggerInit={true}
            load={load}
            countDay={56}
            viewDay={filterMain.viewDay}
            className="wg-main-page__calendar-filter"
            trigger={handleCalendar}
            currentDate={filterMain.currentDate}
            currentCallback={handleCurrentCallback}
        />
    )
})