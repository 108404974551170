import { PersonnelProfile } from '@Types'
import React, {useEffect, useRef} from 'react'
import { BaseAccordeon } from 'src/modules/common'
import './CoachAccardeon.scss'
import {ApiService} from "@Services";

interface CoachAccardeonProps {
    coach?: PersonnelProfile
    tabIndex?: number
    isLoading?: boolean
}

export const CoachAccardeon: React.FC<CoachAccardeonProps> = (props) => {
    const {
        tabIndex = 1,
        coach,
        isLoading,
    } = props

    const ref = useRef<any>();

    if (isLoading || !coach) return (
        <BaseAccordeon
            header={<div className="wg-coach-accordeon-header"></div>}
            body={<></>}
            showArrow={false}
        />
    )

    if(coach.photoData) {
        let data = coach.photoData;
        let reader = new window.FileReader();
        reader.readAsDataURL(data);
        reader.onload = function () {
            let imageDataUrl = reader.result;
            ref.current.setAttribute("style", `background-image: url("${imageDataUrl}");`);
        }
    }

    return (
        <BaseAccordeon
            tabIndex={tabIndex}
            header={<div className="wg-coach-accordeon-header">
                <div
                    style={{backgroundImage: 'url("/static/img/person-icon.png")'}}
                    ref={ref}
                    className="wg-coach-accordeon-header__img"
                />
                <div className="wg-coach-accordeon-header__text-block">
                    <p className="wg-coach-accordeon-header__name">
                        {coach.firstName}
                    </p>
                    <p className="wg-coach-accordeon-header__descr">
                        {coach.surname}
                    </p>
                </div>
            </div>}

            body={<div className="wg-coach-accordeon-body">
                <div className="wg-coach-accordeon-body__item">
                    <p className="wg-coach-accordeon-body__item-title">
                        Квалификация
                    </p>
                    <p className="wg-coach-accordeon-body__item-text enters">
                        {
                            coach.qualifications.map(q => `- ${q.name}\n`)
                        }
                    </p>
                </div>

                <div className="wg-coach-accordeon-body__item">
                    <p className="wg-coach-accordeon-body__item-title">
                        Образование. Специализация
                    </p>
                    <p className="wg-coach-accordeon-body__item-text enters">
                        {coach.education}
                    </p>
                </div>

                <div className="wg-coach-accordeon-body__item full">
                    <p className="wg-coach-accordeon-body__item-title">
                        Спортивные достижения
                    </p>
                    <p className="wg-coach-accordeon-body__item-text enters">
                        {coach.achievements}
                    </p>
                </div>

            </div>}
        />
    )
}

// const coach = {
//     name: 'Шамаева Алина',
//     descr: 'Инструктор групповых программ',
//     imgSrc: 'static/img/test/test-1.jpg',
//     bodyInfo: [
//         {
//             title: `Специализация`,
//             text: `Yoga,
//             stretching,
//             калланетик,
//             йога в гамаках`,
//         },
//         {
//             title: `Опыт работы`,
//             text: `3 года`,
//         },
//         {
//             title: `Спортивные достижения`,
//             text: `-Чемпион урало - приволжской зоны по тайскому боксу, призёр УРФО
//             - Участник всероссийских турниров по тайскому боксу
//             - Участник профессиональных турниров по кик боксингу в разделе К1
//             - Чемпион Свердловской области по ММА`,
//         },
//     ],
// }