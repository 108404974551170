import React from 'react'
import './CircleLoader.scss'

interface CircleLoaderProps {
    className?: string
}

export const CircleLoader: React.FC<CircleLoaderProps> = (props) => {
    const {
        className,
    } = props

    return (
        <div
            className={className ? `${className} wg-circle-loader` : "wg-circle-loader"}
        >
            <div className="wg-circle-loader__body">
                <div className="wg-circle-loader__item"></div>
                <div className="wg-circle-loader__item"></div>
                <div className="wg-circle-loader__item"></div>
                <div className="wg-circle-loader__item"></div>
            </div>
        </div>
    )
}