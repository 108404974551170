export const calcHeight = (node: HTMLElement | HTMLDivElement) => {
    return node.scrollHeight
}

export const clearFocus = (e: React.MouseEvent<HTMLElement>) => {
    const target = e.target as HTMLElement
    if (!target) return null
    if (target.blur) target.blur()
}

export const lockBody = () => document.body.classList.add('lock')
export const unlockBody = () => document.body.classList.remove('lock')