import { BtnCityFilter, BtnIcon, SVGReset } from '@Modules'
import { rootState } from '@State'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { getStoreByIdFromCitites } from 'src/utils/state'
import './HeaderLineMainPage.scss'

interface HeaderLineMainPageProps {
    onClickClub: () => void
}

export const HeaderLineMainPage: React.FC<HeaderLineMainPageProps> = observer((props) => {
    const {
        onClickClub,
    } = props

    const mainPageState = rootState.mainPage
    const filter = rootState.filterMainPage
    const currentClubName = getStoreByIdFromCitites(mainPageState.citiesWithStores, filter.clubId)?.name || ''

    return (
        <div className="wg-header-line-main-page">
            {
                mainPageState.isInitializedStores && mainPageState.showClubFilter
                    ? <BtnCityFilter
                        text={currentClubName || "Выберите клуб"}
                        onClick={onClickClub}
                    />
                    : <p>Клуб: {currentClubName}</p>
            }

            <div className="wg-header-line-main-page__controls">
                <BtnIcon
                    Icon={<SVGReset />}
                    text="Сбросить"
                    className="wg-white-btn wg-header-line-main-page__reset-btn"
                    onClick={() => filter.resetFilter(mainPageState.directionsInStore)}
                />
            </div>
        </div>
    )
})