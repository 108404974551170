import { lockBody, unlockBody } from '@Utils'
import { messageService } from '@Services'
import React, { useEffect, useState } from 'react'
import { CSSTransition } from 'react-transition-group'
import { useEscape } from '../../hooks/useEscape'
import './BaseModal.scss'

interface BaseModalProps {
    isShow: boolean
    onClose: () => void
    onAccept?: () => void
    onDeny?: () => void
    timeout?: number
    className?: string
    customScroll?: boolean
    size?: 'small' | 'medium'
}

export const BaseModal: React.FC<BaseModalProps> = (props) => {
    const {
        isShow,
        timeout = 300,
        className,
        children,
        size = 'medium',
        customScroll = true,
        onClose,
        onDeny,
        onAccept,
    } = props

    const [height, setHeight] = useState<number | string>('0px')
    useEscape(onClose)

    useEffect(() => {
        messageService.sendMessage('SCROLL_LOCK', isShow);
        if (isShow) {
            lockBody()
        } else {
            unlockBody()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isShow])

    useEffect(() => {
        const handler = () => {
            setHeight(window.innerWidth > 600 ? "90vh" : window.innerHeight - 40)
        }
        window.addEventListener('resize', handler)
        handler()
        return () => {
            window.removeEventListener('resize', handler)
        }
    }, [])

    const wrapClasses = () => {
        const classes = ['wg-basemodal']
        if (className) classes.push(className)
        if (size !== 'medium') classes.push(size)
        return classes.join(' ')
    }

    return (
        <CSSTransition
            in={isShow}
            timeout={timeout}
            classNames={{
                enterActive: 'entering',
                enterDone: 'enter',
                exitActive: 'exiting',
            }}
            unmountOnExit
        >
            <div className={wrapClasses()}>
                <div
                    className="wg-basemodal__bg"
                    style={{ transitionDuration: `${timeout}ms` }}
                    onClick={onClose}
                ></div>
                <div
                    className="wg-basemodal__body-wrapper"
                    onClick={onClose}
                >
                    <button
                        className="wg-basemodal__body-close-sm"
                        style={{ animationDuration: `${timeout}ms` }}
                    >
                        <svg width="30" height="30" fill="#fff" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.4 23.5L23.48 4.4l2.12 2.12-19.1 19.1z" />
                            <path d="M6.52 4.4l19.1 19.09-2.13 2.12L4.4 6.5z" />
                        </svg>
                    </button>
                    <div
                        className="wg-basemodal__body-container"
                        style={{ animationDuration: `${timeout}ms` , overflow: customScroll ? 'hidden' : 'visible'}}
                        onClick={e => e.stopPropagation()}
                    >
                        <div className="wg-basemodal__body">
                            {
                                children
                            }
                        </div>
                        {
                            (onDeny || onAccept) &&
                            <div className="wg-basemodal__footer-controls">
                                {
                                    onDeny &&
                                    <button
                                        className="wg-basemodal__footer-deny-btn wg-gray-btn"
                                        onClick={onDeny}
                                    >
                                        Отменить
                                    </button>
                                }
                                {
                                    onAccept &&
                                    <button
                                        className="wg-basemodal__footer-accept-btn wg-primary-btn"
                                        onClick={onAccept}
                                    >
                                        Применить
                                    </button>
                                }
                            </div>
                        }
                    </div>
                </div>
            </div>
        </CSSTransition>
    )
}