import { RadioBtn } from '@Modules'
import { rootState } from '@State'
import { onlyChildrenIdsDirection } from '@Utils'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useMemo } from 'react'
import './DirectionLineMainPage.scss'

interface DirectionLineMainPageProps {

}

export const DirectionLineMainPage: React.FC<DirectionLineMainPageProps> = observer(() => {

    const mainPageState = rootState.mainPage
    const filter = rootState.filterMainPage
    const checkedDirections = filter.directionIds

    // const onlyChildrenIds = (dir: DirectionInStore) => getChildrenIds(dir).filter(id => id !== dir.id)

    const allDirectionsIds = useMemo(() => {
        return mainPageState.directionsInStore
            .map(dir => onlyChildrenIdsDirection(dir))
            .flat(Infinity) as number[]
    }, [mainPageState.directionsInStore])

    const isCheckedAllDirections = allDirectionsIds.length === filter.directionIds.length

    const handleChange = (directionId: number) => {
        if (directionId === 0) {
            // Checked all directions
            filter.setDirectionIds(allDirectionsIds)
            return null
        }

        const currentDirection = mainPageState.directionsInStore.find(d => d.id === directionId)
        if (!currentDirection) return null

        const directionChildrenIds = onlyChildrenIdsDirection(currentDirection)
        const isCheckedAllChildren = directionChildrenIds.every(id => checkedDirections.includes(id))

        if (!isCheckedAllChildren) {
            // Add directions

            // If all directions have been checked, check only one group
            // else concat new directions with old directions
            const prevChecked = isCheckedAllDirections ? [] : checkedDirections
            //
            const withDirection = [...prevChecked, ...directionChildrenIds]
            filter.setDirectionIds(withDirection)
            return null
        }

        if (isCheckedAllDirections) {
            filter.setDirectionIds(directionChildrenIds)
            return null
        }

        // Otherwise remove all children directions
        const withoutDirection = checkedDirections.filter(id => !directionChildrenIds.includes(id))
        if (!withoutDirection.length) {
            filter.setDirectionIds(allDirectionsIds)
            return null
        }
        filter.setDirectionIds(withoutDirection)
    }

    useEffect(() => {
        filter.setDirectionIds(allDirectionsIds)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mainPageState.directionsInStore])

    if (!mainPageState.groupSessions) return null

    if (mainPageState.isLoadingDirectionsInStore) return (
        <div className="wg-direction-line-main-page">
            {
                new Array(6).fill('').map((_, idx) => (
                    <div key={idx} className="wg-direction-line-main-page__item placeholder"></div>
                ))
            }
        </div>
    )

    return (
        <div className="wg-direction-line-main-page">
            <RadioBtn
                type="checkbox"
                text="Все расписание"
                value={0}
                checked={isCheckedAllDirections}
                className="wg-direction-line-main-page__item"
                onChange={e => handleChange(+e.target.value)}
            />
            {
                mainPageState.directionsInStore
                .map(dir => {
                    const checkedAllChildren = onlyChildrenIdsDirection(dir).every(cId => checkedDirections.includes(cId))
                    return (
                        <RadioBtn
                            key={dir.id || Math.random()}
                            type="checkbox"
                            text={dir.name}
                            value={dir.id}
                            checked={
                                checkedAllChildren &&
                                !isCheckedAllDirections
                            }
                            className="wg-direction-line-main-page__item"
                            onChange={e => handleChange(+e.target.value)}
                        />
                    )
                })
            }
        </div>
    )
})