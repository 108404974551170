import React from 'react'

interface SVGDropArrowProps extends React.SVGProps<SVGSVGElement>{
    className?: string
}

export const SVGDropArrow: React.FC<SVGDropArrowProps> = (props) => {
    const {
        className,
        ...svgProps
    } = props

    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="#051932"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            {...svgProps}
        >
            <g opacity="0.3">
                <path d="M9.73205 3C8.96225 1.66666 7.03775 1.66667 6.26795 3L2.80385 9C2.03405 10.3333 2.9963 12 4.5359 12L11.4641 12C13.0037 12 13.966 10.3333 13.1962 9L9.73205 3Z" />
            </g>
        </svg>
    )
}