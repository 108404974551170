import React from 'react'
import './CancelTag.scss'

interface CancelTagProps {
    className?: string
}

export const CancelTag: React.FC<CancelTagProps> = (props) => {
    const {
        className
    } = props

    return (
        <div className={className ? `wg-cancel-tag ${className}` : "wg-cancel-tag"}>
            <span className="wg-cancel-tag__text">
                Отмена
            </span>
        </div>
    )
}