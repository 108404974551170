import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from "react";
import './Calendar.scss';
import moment from 'moment-timezone'

export const Calendar = ({ countDay, trigger, load, isTriggerInit, currentCallback, currentDate, className = "", viewDay = true }) => {

    const [width, setWidth] = useState('0px');
    const [size, setSize] = useState(0);
    const [style, setStyle] = useState({});
    const [styleWeek, setStyleWeek] = useState({});
    const [current, setCurrent] = useState(currentDate);
    const [offset, setOffset] = useState(0);
    const [week, setWeek] = useState(0);
    const [dateSt, setDateSt] = useState('');

    const firstDate = useRef(null)

    const updateSize = () => {
        let elem = document.querySelector('.bf-calendar');
        if (elem) {
            let styleOffset = getComputedStyle(elem);

            if (styleOffset.width !== '0px') {
                setWidth(styleOffset.width);
            }
        }
        let root = document.querySelector('#root');
        if (root) {
            let styleOffset = getComputedStyle(root);
            if (styleOffset.width !== '0px') {
                setOffset(styleOffset.width);
            }
        }
    };

    const getMonthRus = (month) => {
        switch (month) {
            case 0:
                return 'января';
            case 1:
                return 'февраля';
            case 2:
                return 'марта';
            case 3:
                return 'апреля';
            case 4:
                return 'мая';
            case 5:
                return 'июня';
            case 6:
                return 'июля';
            case 7:
                return 'августа';
            case 8:
                return 'сентября';
            case 9:
                return 'октября';
            case 10:
                return 'ноября';
            case 11:
                return 'декабря';
        }
    };

    const getDayOfWeek = (day) => {
        switch (day) {
            case 0:
                return 'Воскресенье';
            case 1:
                return 'Понедельник';
            case 2:
                return 'Вторник';
            case 3:
                return 'Среда';
            case 4:
                return 'Четверг';
            case 5:
                return 'Пятница';
            case 6:
                return 'Суббота';
        }
    };

    const clickForDate = (item, week, date) => {
        if (trigger) {
            setDateSt(item.date);
            trigger('date', item.date);
            currentCallback(week, date);
        }
        if (!item.disable)
            checkNewCurrent(week, date);
    };


    const checkNewCurrent = (week, day, isUpdate=true) => {

        let el = document.querySelector('.bf-calendar-week');
        let styleOffset = getComputedStyle(el);
        let ofMargin = '0px';
        if (styleOffset) {
            ofMargin = styleOffset.marginLeft;
        }

        if (current[0] !== null) {
            const appContainer = document.querySelector('#wg-cont')
            if (!appContainer) return console.warn('App container not found')
            const padding = +window
                .getComputedStyle(appContainer, null)
                .getPropertyValue('padding-left')
                .replace('px', '')
                .replace('%', '')

            if (current[0] !== week) {

                let elemWeek = document.querySelector('.bf-calendar-week[data-week="' + week + '"]');
                let cont = document.querySelector('.bf-calendar-visible-item');
                if (elemWeek) {
                    let elemDay = elemWeek.querySelector('.bf-calendar-item[data-day="' + day + '"]');
                    let el = document.querySelector('.bf-calendar-current');
                    let boxCont = cont.scrollLeft;
                    if (elemDay) {
                        let box = elemDay.getBoundingClientRect();
                        let weekBox = elemWeek.getBoundingClientRect();


                        if (ofMargin !== '0px') {
                            // el.setAttribute('style', `top: 0; left: calc(${ofMargin} + px * '+(day)+')); width: '+box.width+'px; height: '+(box.height-2)+'px;`);


                            el.setAttribute('style', `top: 0; left: calc(${ofMargin} + ${box.x - weekBox.x}px); width: ${box.width}px; height: ${box.height - 2}px;`);

                            // el.setAttribute('style', 'top: 0; left: calc('+ofMargin+' + calc('+(box.width+10)+'px * '+(day)+')); width: '+box.width+'px; height: '+(box.height-2)+'px;');
                            setStyle({
                                width: style.width,
                                transform: 'translate(calc(' + (-week) + ' * ' + width + '), 0px)',
                                transition: 'all 0.3s ease'
                            });
                        } else {

                            el.setAttribute('style', 'top: 0; left: ' + ((box.left + boxCont) - padding) + 'px; width: ' + box.width + 'px; height: ' + (box.height - 2) + 'px;');
                            cont.scrollTo({// сдвиг в центр выбранной даты при переходе между неделями
                                top: 0,
                                left: cont.scrollLeft + (box.x + box.width / 2 - cont.getBoundingClientRect().width / 2) - padding,
                                behavior: 'smooth'
                            });
                        }
                    }
                }
            } else {

                let elemWeek = document.querySelector('.bf-calendar-week[data-week="' + week + '"]');
                let cont = document.querySelector('.bf-calendar-visible-item');
                if (elemWeek) {

                    let elemDay = elemWeek.querySelector('.bf-calendar-item[data-day="' + day + '"]');
                    let el = document.querySelector('.bf-calendar-current');
                    if (elemDay) {

                        let box = elemDay.getBoundingClientRect();
                        let weekBox = elemWeek.getBoundingClientRect();
                        let boxCont = cont.scrollLeft;

                        if (ofMargin !== '0px') {

                            el.setAttribute('style', `top: 0; left: calc(${ofMargin} + ${box.x - weekBox.x}px); width: ${box.width}px; height: ${box.height - 2}px;`);
                            // el.setAttribute('style', 'top: 0; left: calc(' + ofMargin + ' + calc(' + (box.width + 10) + 'px * ' + (day) + ')); width: ' + box.width + 'px; height: ' + (box.height-2) + 'px;');

                        } else {

                            el.setAttribute('style', 'top: 0; left: ' + ((box.left + boxCont) - padding) + 'px; width: ' + box.width + 'px; height: ' + (box.height - 2) + 'px;');
                            //cont.scrollLeft = cont.scrollLeft + (box.x - box.width/2 - cont.getBoundingClientRect().width /2);
                            cont.scrollTo({ //сдвиг в центр выбранной даты при изменении даты внутри недели
                                top: 0,
                                left: cont.scrollLeft + (box.x + box.width / 2 - cont.getBoundingClientRect().width / 2) - padding,
                                behavior: 'smooth'
                            });
                        }
                    }
                }
            }

        } else {
            let elemWeek = document.querySelector('.bf-calendar-week[data-week="' + week + '"]');
            let cont = document.querySelector('.bf-calendar-visible-item');
            if (elemWeek) {

                let elemDay = elemWeek.querySelector('.bf-calendar-item[data-day="' + day + '"]');
                let el = document.querySelector('.bf-calendar-current');
                if (elemDay) {

                    let box = elemDay.getBoundingClientRect();
                    let boxCont = cont.scrollLeft;
                    if (ofMargin !== '0px') {

                        el.setAttribute('style', 'top: 0; left: calc(' + ofMargin + ' + calc(' + (box.width + 10) + 'px * ' + (day) + ')); width: ' + box.width + 'px; height: ' + (box.height - 2) + 'px;');
                    } else {

                        el.setAttribute('style', 'top: 0; left: ' + ((box.left + boxCont) - 15) + 'px; width: ' + box.width + 'px; height: ' + (box.height - 2) + 'px;'); cont.scrollTo({
                            top: 0,
                            left: cont.scrollLeft + (box.x + box.width / 2 - cont.getBoundingClientRect().width / 2) - 15,
                            behavior: 'smooth'
                        });
                    }
                }
            }
        }
        if(isUpdate) {
            setCurrent([week, day]);
            setWeek(week);
        }
    };

    let s = window.location.search;
    s = s.match(new RegExp("date" + '=([^&=]+)'));
    let getDate = s ? s[1] : false;

    let date = moment.utc(moment.utc().tz('Asia/Yekaterinburg').format()).tz('Asia/Yekaterinburg');
    let now = moment.utc(date.format()).tz('Asia/Yekaterinburg');
    let day = date.day() - 1;

    if (day === -1) {
        date.subtract(6, 'days');
    } else {
        date.subtract(day, 'days');
    }

    let dd = [];
    for (let i = 0; i < countDay; i++) {
        let item = {};
        item.disable = false;
        if (now > date) {
            item.disable = true;
        }
        item.date = date.format('YMMDD');
        if (getDate == item.date) {
            item.current = true;
        }
        item.display = date.date() + ' ' + getMonthRus(date.month());
        item.dayOfWeek = getDayOfWeek(date.day());
        dd.push(item);

        date.add(1, 'days');
    }

    let ss = 7;
    let totalDays = [];

    for (let i = 0; i < Math.ceil(dd.length / ss); i++) {
        totalDays[i] = dd.slice((i * ss), (i * ss) + ss);
    }
    let isCurrentState = false;
    let currentCourse = [0, 0];

    const isMobile = () => window.innerWidth < 1028

    let disp = totalDays.map((value, index) => {
        return (
            <div className="bf-calendar-week" data-week={index} key={index} style={styleWeek}>
                {value.map((item, idx) => {
                    if ((!isCurrentState && !item.disable) || (item.current && !item.disable)) {
                        isCurrentState = true;
                        currentCourse = [index, idx];
                    }
                    if (!item.disable && !firstDate.current) {
                        firstDate.current = item.date
                    }
                    const handleChoose = () => !item.disable ? clickForDate(item, index, idx) : ''
                    const sholudTab = () => index === week || isMobile() ? "1" : undefined
                    return (<div
                        className={'bf-calendar-item bf-text bf-font-bold ' + (item.disable ? 'bf-calendar-item-disable' : '')}
                        tabIndex={sholudTab()}
                        onKeyDown={e => e.key === 'Enter' ? handleChoose() : null}
                        key={item.date}
                        onClick={handleChoose}
                        data-day={idx}
                    >
                        <span>
                            {item.display}
                        </span>
                        <br />
                        <span className="bf-calendar-item-day">{item.dayOfWeek}
                        </span>
                    </div>);
                })}
            </div>
        );
    });

    const changeWeek = (newWeek) => {
        updateSize()

        if (newWeek >= 0 && newWeek < size) {
            let idx = week < newWeek ? 0 : 6;
            clickForDate(totalDays[newWeek][idx], newWeek, idx);
        }
    };

    const widthAllWeeks = () => {
        let sumSize = 0
        let weeks = document.querySelectorAll('.bf-calendar-week');
        weeks.forEach(w => sumSize += w.getBoundingClientRect().width)
        return sumSize
    }

    useEffect(() => {
        if (load) {
            updateSize();
        }
    }, [load]);

    useEffect(() => {
        window.addEventListener('resize', updateSize);

        setSize(totalDays.length);
        let el = document.querySelector('.bf-calendar-visible-item');

        let weeks = 0;
        if (current[0] === null) {
            checkNewCurrent(currentCourse[0], currentCourse[1]);
            weeks = currentCourse[0];
        } else {
            checkNewCurrent(current[0], current[1]);
            weeks = current[0];
        }
        let weekWidth = 0;
        if (isMobile()) {

            weekWidth = 'auto';
            setStyle({
                width: widthAllWeeks(),
                transform: 'translate(0px, 0px)'
            });
        } else {
            weekWidth = el.getBoundingClientRect().width - 220;
            setStyle({
                width: el.getBoundingClientRect().width * totalDays.length,
                transform: 'translate(calc(' + (-weeks) + ' * ' + width + '), 0px)'
            });
        }
        setStyleWeek({
            width: weekWidth
        });

        return () => {
            window.removeEventListener('resize', updateSize);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [width]);

    useEffect(() => {
        if (isTriggerInit && firstDate.current) {
            setDateSt(firstDate.current);
            trigger('date', firstDate.current)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [firstDate.current])

    useEffect(() => {
        if(trigger){
            trigger('date', dateSt)
        }
    }, [viewDay]);

    return (
        <div className={"bf-calendar" + ` ${className}`}>
            <div
                className={"bf-calendar-arrow bf-calendar-arrow-left" + (week === 0 ? ' bf-calendar-item-disable' : '')}
                tabIndex="1"
                onKeyDown={e => e.key === 'Enter' ? changeWeek(week - 1) : undefined}
                onClick={() => changeWeek(week - 1)}
            >
                <div className="bf-calendar-area-icon">
                    <svg className="bf-calendar-area-icon-prev" width="22" height="17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 8.5h20m0 0L13.45 1M21 8.5L13.45 16" stroke="#111034" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </div>
            </div>
            <div
                className="bf-calendar-visible-item">
                <div
                    className="bf-calendar-container"
                    style={style}
                >
                    {disp}
                </div>
                <div className="bf-calendar-current" /></div>
            <div
                className={"bf-calendar-arrow bf-calendar-arrow-right" + (week === size - 1 ? ' bf-calendar-item-disable' : '')}
                tabIndex="1"
                onKeyDown={e => e.key === 'Enter' ? changeWeek(week + 1) : undefined}
                onClick={() => changeWeek(week + 1)}
            >
                <div className="bf-calendar-area-icon">
                    <svg className="bf-calendar-area-icon-next" width="22" height="17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 8.5h20m0 0L13.45 1M21 8.5L13.45 16" stroke="#111034" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </div>
            </div>
        </div>
    );

};

Calendar.propTypes = {
    countDay: PropTypes.number,
    trigger: PropTypes.func,
    windowSize: PropTypes.object
};